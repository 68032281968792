import { TemplateResource } from '@/types/graphql'

import { gql, useQuery } from '@apollo/client'
import { Form } from 'antd'
import { Select } from 'antd-v5'
import { flow, uniq } from 'lodash'
import { map, sortBy } from 'lodash/fp'

import useBaseCompanyType from '@/hooks/useBaseCompanyType'
import { voucherTypes } from '@/utils/labelMap'
import { logger } from '@/utils/logger'
import respHandler from '@/utils/responseHandler'

const processTypes = flow(
  uniq,
  map(t => ({ label: voucherTypes[t as any]?.text || t, type: t })),
  sortBy(['label'])
)

const VoucherTypeFilterFormItem = props => {
  const {
    form: { getFieldDecorator },
    value
  } = props

  const { data: currentCompanyData = [] } = useBaseCompanyType()

  const bookingTypes = currentCompanyData?.map(d => d?.meta.type)

  let actionTemplatesGql = ''

  bookingTypes?.forEach(bt => {
    actionTemplatesGql += `actionTemplates${bt}: actionTemplates(resource: $resource, bookingType:"${bt}") {
      ... on VoucherTemplate {
        type
      }
    }`
  })

  const query = `
    query getActionTemplates ($resource: templateResource!) {
      ${actionTemplatesGql}
    }
  `

  const voucherTypeGql = gql(query)

  const { error: queryError, data: voucherTypesQuery } = useQuery(voucherTypeGql, {
    variables: { resource: TemplateResource.Voucher }
  })

  if (queryError) {
    logger.error('VoucherTypeFilterFormItem voucherTypeGql error', queryError)
    respHandler(queryError, 'error')
  }

  if (bookingTypes?.length || 0 < 1) return null

  let voucherTypes: any = []

  if (voucherTypesQuery) {
    for (const key in voucherTypesQuery) {
      voucherTypes.push(processTypes([...(voucherTypesQuery[key]?.map(t => t.type) || [])]))
    }
  }

  voucherTypes = voucherTypes.flat()
  const options = voucherTypes?.map(t => ({ label: t.label, value: t.type }))

  return (
    <Form.Item label="Voucher Type">
      {getFieldDecorator('voucherType', {
        initialValue: value
      })(<Select mode="multiple" allowClear placeholder="Please select" options={options} />)}
    </Form.Item>
  )
}

export default VoucherTypeFilterFormItem
