import { Flex, Table } from 'antd-v5'
import moment from 'moment'

import { PrintDocumentCreator } from 'App/components/Shared/documentCreator/openPrintableInNewTab'
import ConsolVoucherModalButton from 'App/components/Voucher/Management/ConsolVoucherModalButton'
import ConsolVoucherPdfAsyncDownloadAction from 'App/components/Voucher/Management/ConsolVoucherPdfAsyncDownloadAction'
import useGlobalCompanyStore from 'App/store/globalCompany'
import CustomText from '../CustomText'
import { TableWrapper } from '../Styled'
import { isApproved } from '../Utils'

const getColumns = (props, selectedGlobalCompany) => {
  const { onChange } = props

  return [
    {
      title: 'Issue Date',
      dataIndex: 'issueDate',
      key: 'issueDate',
      render: text => <CustomText text={moment(text).format('ll')} />
    },
    {
      title: 'Reference',
      dataIndex: 'reference',
      key: 'reference'
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type'
    },
    {
      title: 'Company',
      key: 'company',
      render: (text, record) => {
        const firstVoucher = record.vouchers[0]
        const transactionType = firstVoucher.transactionType
        const company = transactionType === 'ACCREC' ? firstVoucher.customer : firstVoucher.vendor

        return company.name
      }
    },
    {
      title: 'Debtor/Creditor code',
      key: 'code',
      render: (text, record) => {
        const firstVoucher = record.vouchers[0]
        const transactionType = firstVoucher.transactionType
        const company = transactionType === 'ACCREC' ? firstVoucher.customer : firstVoucher.vendor

        const code = transactionType === 'ACCREC' ? company.debtorCode : company.creditorCode

        return code
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status'
    },
    {
      key: 'action',
      render: (text, record) => {
        return (
          <Flex gap="small">
            <ConsolVoucherModalButton consolVoucher={record} onChange={onChange} />

            {isApproved(record) && (
              <PrintDocumentCreator
                type="CONSOLE_VOUCHER"
                modelUUID={record.uuid}
                baseCompanyUuid={selectedGlobalCompany.uuid}
              />
            )}
            <ConsolVoucherPdfAsyncDownloadAction consolVoucher={record} type="CONSOL_INVOICE" />
          </Flex>
        )
      }
    }
  ]
}

const getPagination = props => {
  const { total = 20, pageSize = 20, currentPage = 1, onPageChange } = props

  return {
    total,
    pageSize,
    current: currentPage,
    onChange: onPageChange
  }
}

const rowKey = consolVoucher => consolVoucher.uuid

const ConsolVoucherTable = props => {
  const { consolVouchers } = props
  const selectedGlobalCompany = useGlobalCompanyStore.use.selectedGlobalCompany()

  return (
    <TableWrapper>
      <Table
        rowKey={rowKey}
        dataSource={consolVouchers}
        pagination={getPagination(props)}
        columns={getColumns(props, selectedGlobalCompany)}
      />
    </TableWrapper>
  )
}

export default ConsolVoucherTable
