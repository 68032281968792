import type { EventsProps } from '@/components/Shared/Events'

import { useState } from 'react'
import { List } from 'antd'
import { Avatar, Button, Modal, Row, Tooltip, Typography } from 'antd-v5'
import moment from 'moment'

import { getActTime } from 'App/components/RelativeDateTime'
import { EventTime, EventTitleSpan, StyledEventListItem } from './Styled'

const { Text } = Typography

const EventListItem = (props: EventsProps & { event }) => {
  const { event, title, subTitle } = props
  const { payload } = event

  const [show, setShow] = useState(false)
  const updateModalState = () => setShow(!show)

  const prettifiedJsonString =
    typeof payload === 'string'
      ? JSON.stringify(JSON.parse(payload), null, 2)
      : JSON.stringify(payload, null, 2)

  return (
    <StyledEventListItem>
      <List.Item.Meta
        avatar={<Avatar src={event.user.picture} size={24} />}
        title={
          <EventTitleSpan onClick={updateModalState}>
            <Row>
              {title?.(event) || (event.context && event.event)
                ? `${event.context}:${event.event}`
                : event.event}
            </Row>
            {subTitle?.(event) && (
              <Row>
                <Text type="secondary">{subTitle(event)}</Text>
              </Row>
            )}
            <Row>
              <Text disabled>
                {event.user.nickname
                  ? `${event.user.nickname} <${event.user.email}>`
                  : event.user.email}
              </Text>
            </Row>
          </EventTitleSpan>
        }
      />
      <Modal
        title={`Event ${event.model || ''}:${event.event} (${
          event.user.nickname ? `${event.user.nickname} <${event.user.email}>` : event.user.email
        }) @${getActTime(event.time)}`}
        width="80vw"
        open={show}
        onCancel={updateModalState}
        onOk={updateModalState}
        footer={[
          <Button key="back" onClick={updateModalState}>
            Okay
          </Button>
        ]}
      >
        <pre>{prettifiedJsonString}</pre>
      </Modal>
      <Tooltip title={moment(event.time).format('LLL')}>
        <EventTime>{getActTime(event.time)}</EventTime>
      </Tooltip>
    </StyledEventListItem>
  )
}

export default EventListItem
