import { Component } from 'react'
import { Provider } from 'react-redux'

import initRedux from '../libs/initRedux'

const store = initRedux()

export default WrappedComponent => {
  class WithRedux extends Component {
    constructor(props) {
      super(props)
    }

    render() {
      return (
        <Provider store={store}>
          <WrappedComponent {...this.props} />
        </Provider>
      )
    }
  }

  return WithRedux
}

// We can use RootState type in every file in project
declare global {
  type RootState = ReturnType<typeof store.getState>
}
