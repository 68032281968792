import { Component } from 'react'
import { Empty, Flex, Skeleton } from 'antd-v5'

import ConsolVoucherFilter from 'App/components/Voucher/Management/ConsolVoucherFilter'
import ConsolVoucherTable from 'App/components/Voucher/Management/ConsolVoucherTable'
import consolVouchersQuery from 'App/containers/consolVoucher/query/consolVouchers'
import { logger } from 'App/utils/logger'
import handleResponse from 'App/utils/responseHandler'

const ConsolVoucherManagement = class extends Component {
  async componentDidUpdate(prevProps) {
    const { consolVouchersQuery } = this.props

    if (this.props.lastUpdateTime !== prevProps.lastUpdateTime) {
      await consolVouchersQuery.refetch()
    }

    if (consolVouchersQuery?.error) {
      logger.error('ConsolVoucherManagement consolVouchersQuery Error.', consolVouchersQuery?.error)
      handleResponse(consolVouchersQuery?.error, 'error')
    }
  }

  handleSearch = async values => {
    const { consolVouchersQuery } = this.props

    await consolVouchersQuery.refetch(values)
  }

  handleChange = async () => {
    const { consolVouchersQuery } = this.props

    await consolVouchersQuery.refetch()
  }

  handlePageChange = async (page, pageSize) => {
    const { consolVouchersQuery } = this.props

    await consolVouchersQuery.fetchMore({
      variables: {
        limit: pageSize,
        offset: (page - 1) * pageSize
      },
      updateQuery: ({ fetchMoreResult }) => {
        return fetchMoreResult
      }
    })
  }

  render() {
    const { consolVouchersQuery } = this.props

    if (consolVouchersQuery?.loading) return <Skeleton active />

    if (!consolVouchersQuery || !consolVouchersQuery.consolVouchers) return <Empty />

    const consolVouchers = consolVouchersQuery.consolVouchers.rows
    const pageInfo = consolVouchersQuery.consolVouchers.pageInfo

    return (
      <Flex vertical gap="middle">
        <ConsolVoucherFilter onSearch={this.handleSearch} />
        <ConsolVoucherTable
          consolVouchers={consolVouchers}
          total={pageInfo.count}
          pageSize={pageInfo.limit}
          currentPage={pageInfo.offset + 1}
          onChange={this.handleChange}
          onPageChange={this.handlePageChange}
        />
      </Flex>
    )
  }
}

export default consolVouchersQuery(ConsolVoucherManagement)
