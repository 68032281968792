import { useEffect, useState } from 'react'
import { find } from 'lodash'
import { compose } from 'recompose'

import setBookingAssigneeMutation from '@/containers/booking/mutation/setBookingAssignee'
import unsetBookingAssigneeMutation from '@/containers/booking/mutation/unsetBookingAssignee'
import usersQuery from '@/containers/user/query/users'

const withAssignee = WrappedComponent => {
  const WithAssignee = props => {
    const { booking } = props

    const [editMode, toggleEdit] = useState(false)
    const [selectedUser, updateSelectedUser] = useState({})

    useEffect(() => {
      if (booking?.assignees?.length) {
        updateSelectedUser(booking.assignees[0] && booking.assignees[0].user)
      }
    }, [booking])

    const focusToField = () => {
      toggleEdit(true)
    }

    const focusOutField = () => {
      toggleEdit(false)
    }

    const handleSelect = async (value, option) => {
      const { setBookingAssignee, booking } = props
      try {
        await setBookingAssignee({
          bookingUuid: booking.uuid,
          userUuid: option.props.user.uuid
        })

        updateSelectedUser(option.props.user)
      } catch (error) {
        console.log('WithAssignee error:', error)
      }
    }

    const handleDeselect = async value => {
      const { booking, unsetBookingAssignee } = props

      const selectedAssignee = find(booking.assignees, assignee => assignee.user.uuid === value)

      await unsetBookingAssignee({
        uuid: selectedAssignee.uuid
      })

      updateSelectedUser(selectedAssignee && selectedAssignee.user)
    }

    return (
      <WrappedComponent
        handleSelect={handleSelect}
        handleDeselect={handleDeselect}
        focusToField={focusToField}
        focusOutField={focusOutField}
        editMode={editMode}
        selectedUser={selectedUser}
        {...props}
      />
    )
  }

  return compose(usersQuery, setBookingAssigneeMutation, unsetBookingAssigneeMutation)(WithAssignee)
}

export default withAssignee
