import type { InputProps } from 'antd-v5'
import type { ChangeEvent } from 'react'

import { Input } from 'antd-v5'

type DynamicInputTextProps = InputProps & {
  isCapitalize?: boolean
  isNonEditable?: boolean
}

const DynamicInputText = (props: DynamicInputTextProps) => {
  const {
    isCapitalize = false,
    isNonEditable = false,
    onChange,
    placeholder = `Enter text`,
    ...rest
  } = props

  return (
    <Input
      {...rest}
      placeholder={placeholder}
      onChange={e => {
        const val = isCapitalize ? e.target.value.toUpperCase() : e.target.value
        onChange?.(val as unknown as ChangeEvent<HTMLInputElement>)
      }}
      disabled={isNonEditable}
    />
  )
}

export default DynamicInputText
