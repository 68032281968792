import type { MenuProps } from 'antd-v5'
import type { WrappedFormUtils } from 'antd/lib/form/Form'

import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { EllipsisOutlined } from '@ant-design/icons'
import { Form } from 'antd'
import { Button, Card, Checkbox, Col, Divider, Dropdown, Input, Row, Skeleton } from 'antd-v5'
import pick from 'lodash/pick'
import moment from 'moment'
import { parse, stringify } from 'query-string'
import styled from 'styled-components'

import AccountDateFilterFormItem from '@/components/Voucher/Management/VoucherFilter/components/AccountDateFilterFormItem'
import ApprovedCountFilterFormItem from '@/components/Voucher/Management/VoucherFilter/components/ApprovedCountFilterFormItem'
import BookingNoFilterFormItem from '@/components/Voucher/Management/VoucherFilter/components/BookingNoFilterFormItem'
import CreditNoteFilterFormItem from '@/components/Voucher/Management/VoucherFilter/components/CreditNoteFilterFormItem'
import CustomerFilterFormItem from '@/components/Voucher/Management/VoucherFilter/components/CustomerFilterFormItem'
import DayAgingFilterFormItem from '@/components/Voucher/Management/VoucherFilter/components/DayAgingFilterFormItem'
import IssueDateFilterFormItem from '@/components/Voucher/Management/VoucherFilter/components/IssueDateFilterFormItem'
import JournalVoucherFilterFormItem from '@/components/Voucher/Management/VoucherFilter/components/JournalVoucherFilterFormItem'
import PaymentPaidFilterFormItem from '@/components/Voucher/Management/VoucherFilter/components/PaymentPaidFilterFormItem'
import PaymentRequestFilterFormItem from '@/components/Voucher/Management/VoucherFilter/components/PaymentRequestFilterFormItem'
import TotalValueFilterFormItem from '@/components/Voucher/Management/VoucherFilter/components/TotalValueFilterFormItem'
import TransactionTypeFilterFormItem from '@/components/Voucher/Management/VoucherFilter/components/TransactionTypeFilterFormItem'
import VendorFilterFormItem from '@/components/Voucher/Management/VoucherFilter/components/VendorFilterFormItem'
import VoucherStatusFilterFormItem from '@/components/Voucher/Management/VoucherFilter/components/VoucherStatusFilterFormItem'
import VoucherTypeFilterFormItem from '@/components/Voucher/Management/VoucherFilter/components/VoucherTypeFilterFormItem'
import voucherFilterKeyLabels from '@/components/Voucher/Management/VoucherFilter/VoucherFilterKeyLabels'
import useBaseCompanyType from '@/hooks/useBaseCompanyType'
import useProcessPortalUser from '@/hooks/useProcessPortalUser'

const StyledCard = styled(Card)`
  padding: 15px;
  height: 100%;
  margin-bottom: 8px;

  .ant-card-body {
    padding: 0;
  }
`

const StyledCol = styled(Col)`
  padding: 4px;
`

type VoucherFilterFormProps = {
  form: WrappedFormUtils
  query: any
  setQuery: (query: any) => void
  refetch: (query: any) => void
  loggedInUser: any
}

const VoucherFilterForm = (props: VoucherFilterFormProps) => {
  const { form, query, setQuery, refetch, loggedInUser } = props
  const { getFieldDecorator, resetFields, validateFields } = form

  const navigate = useNavigate()
  const location = useLocation()
  const { data = [] } = useBaseCompanyType()
  const { loading, isPortalUser } = useProcessPortalUser(loggedInUser)

  const isTransport = useMemo(() => !!data?.find((d: any) => d?.meta?.type === 'TRANSPORT'), [data])

  const [visible, setVisible] = useState(false)
  const [selectedFilters, setSelectedFilters] = useState<string[]>([])

  const filterTypes = voucherFilterKeyLabels.map((item: any) => item.key)

  const handleVisibleChange = useCallback(() => setVisible(!visible), [visible])

  useEffect(() => {
    const fromQuery = parse(location.search)
    const filterQuery = pick(fromQuery, filterTypes)
    const q = pick(fromQuery, ['?q'])['?q']

    setSelectedFilters(Object.keys(fromQuery))

    setQuery({
      ...query,
      q: q || '',
      filter: {
        ...filterQuery,
        ...(filterQuery.approvedCount && {
          approvedCount: filterQuery.approvedCount
            ? parseInt(filterQuery?.approvedCount as string)
            : undefined
        })
      }
    })
  }, [])

  const formatQuery = useCallback(
    (query: any) => {
      const formattedQuery = { ...query }

      if (formattedQuery.filter?.customerUuid) {
        formattedQuery.filter.customerUuid = formattedQuery.filter.customerUuid.map(
          (item: any) => item.key || item
        )
      }

      if (formattedQuery.filter?.isCreditNote) {
        formattedQuery.filter.isCreditNote =
          formattedQuery.filter.isCreditNote === true ||
          formattedQuery.filter.isCreditNote === 'true'
      }

      if (formattedQuery.filter?.isJournalVoucher) {
        formattedQuery.filter.isJournalVoucher =
          formattedQuery.filter.isJournalVoucher === true ||
          formattedQuery.filter.isJournalVoucher === 'true'
      }

      if (formattedQuery.filter?.accountDate?.length) {
        formattedQuery.filter.accountDate[0] = moment(formattedQuery.filter.accountDate[0])
          .startOf('day')
          .toDate()
        formattedQuery.filter.accountDate[1] = moment(formattedQuery.filter.accountDate[1])
          .endOf('day')
          .toDate()
      }

      if (formattedQuery.filter?.issueDate?.length) {
        formattedQuery.filter.issueDate[0] = moment(formattedQuery.filter.issueDate[0])
          .startOf('day')
          .toDate()
        formattedQuery.filter.issueDate[1] = moment(formattedQuery.filter.issueDate[1])
          .endOf('day')
          .toDate()
      }

      return formattedQuery
    },

    [query]
  )

  const parseQuery = useCallback(() => {
    const formattedQuery = formatQuery(query)
    if (query.filter) {
      setSelectedFilters(Object.keys(formattedQuery.filter))
    }
    const withoutFilter = pick(formattedQuery, ['q', 'limit', 'offset'])
    const queryString = stringify(withoutFilter) + '&' + stringify(formattedQuery.filter)
    navigate(`/vouchers?${queryString}`)
  }, [formatQuery, query])

  const handleSearch = useCallback(() => {
    validateFields(async (err: any, values: any) => {
      if (err) return

      if (values.issueDate) {
        values.issueDate = [values.issueDate.from, values.issueDate.to]
      }

      if (values.accountDate) {
        values.accountDate = [values.accountDate.from, values.accountDate.to]
      }

      if (values.dueDate) {
        const oneDayMilliseconds = 86400 * 1000

        const fromDueDateUnix =
          new Date().getTime() - values.dueDate.to * oneDayMilliseconds - oneDayMilliseconds

        const toDueDateUnix = new Date().getTime() - values.dueDate.from * oneDayMilliseconds
        values.dueDate = [fromDueDateUnix, toDueDateUnix]
      }

      if (values.paymentPaid) {
        values.paymentPaid = [values.paymentPaid.from, values.paymentPaid.to]
      }

      if (values.total) {
        values.total = [values.total.from, values.total.to]
      }

      const filtersParams = pick(values, filterTypes)

      setQuery({
        q: values.q || '',
        offset: 0,
        filter: {
          ...filtersParams
        }
      })
    })
  }, [filterTypes, setQuery, validateFields])

  const handleArNeedsApproval = useCallback(() => {
    setQuery({
      ...query,
      filter: {
        status: ['SUBMITTED'],
        transactionType: ['ACCREC']
      }
    })
  }, [query, setQuery])

  const handleArNeedsFinApproval = useCallback(() => {
    setQuery({
      ...query,
      filter: {
        status: ['SUBMITTED'],
        transactionType: ['ACCREC'],
        approvedCount: 1
      }
    })
  }, [query, setQuery])

  const handleApSubmittedHaulage = useCallback(() => {
    setQuery({
      ...query,
      filter: {
        status: ['SUBMITTED'],
        transactionType: ['ACCPAY'],
        voucherType: ['expHaulageBill', 'impHaulageBill', 'HaulageBill']
      }
    })
  }, [query, setQuery])

  const handleClearAll = useCallback(() => {
    resetFields()
    setSelectedFilters([])

    setQuery({
      q: '',
      limit: 20,
      offset: 0,
      filter: {}
    })

    navigate('/vouchers')
  }, [form, setQuery])

  const handleCheckboxChange = useCallback(
    (type: any) => {
      if (selectedFilters.includes(type.target.value)) {
        const unchecked = selectedFilters.filter((key: string) => key !== type.target.value)
        return setSelectedFilters(unchecked)
      }
      setSelectedFilters([...selectedFilters, type.target.value])
    },
    [selectedFilters]
  )

  useEffect(() => {
    const formattedQuery = formatQuery(query)
    refetch(formattedQuery)
    parseQuery()
  }, [query, formatQuery, parseQuery, refetch])

  if (loading) return <Skeleton active />

  const items: MenuProps['items'] = voucherFilterKeyLabels.map(f => {
    return {
      key: f.key,
      label: (
        <Checkbox
          value={f.key}
          style={{ width: '100%' }}
          onChange={handleCheckboxChange}
          checked={selectedFilters.includes(f.key)}
        >
          {f.label}
        </Checkbox>
      )
    }
  })

  return (
    <Form>
      <div className="flex justify-between mb-4 gap-8">
        {getFieldDecorator('q', {
          initialValue: query?.q || ''
        })(<Input autoComplete="off" onPressEnter={handleSearch} placeholder="Search keyword" />)}

        <Dropdown menu={{ items }} open={visible} onOpenChange={handleVisibleChange}>
          <Button icon={<EllipsisOutlined />} />
        </Dropdown>
      </div>

      <Row gutter={8}>
        {selectedFilters.includes('transactionType') && (
          <StyledCol span={8}>
            <StyledCard>
              <TransactionTypeFilterFormItem form={form} value={query.filter?.transactionType} />
            </StyledCard>
          </StyledCol>
        )}

        {selectedFilters.includes('voucherType') && (
          <StyledCol span={8}>
            <StyledCard>
              <VoucherTypeFilterFormItem form={form} value={query.filter?.voucherType} />
            </StyledCard>
          </StyledCol>
        )}

        {selectedFilters.includes('vendorUuid') && (
          <StyledCol span={8}>
            <StyledCard>
              <VendorFilterFormItem form={form} value={query.filter?.vendor} />
            </StyledCard>
          </StyledCol>
        )}

        {selectedFilters.includes('customerUuid') && (
          <StyledCol span={8}>
            <StyledCard>
              <CustomerFilterFormItem form={form} value={query.filter?.customer} />
            </StyledCard>
          </StyledCol>
        )}

        {selectedFilters.includes('paymentType') && (
          <StyledCol span={8}>
            <StyledCard>
              <PaymentRequestFilterFormItem form={form} value={query.filter?.paymentType} />
            </StyledCard>
          </StyledCol>
        )}

        {selectedFilters.includes('status') && (
          <StyledCol span={8}>
            <StyledCard>
              <VoucherStatusFilterFormItem form={form} value={query.filter?.status} />
            </StyledCard>
          </StyledCol>
        )}

        {selectedFilters.includes('approvedCount') && (
          <StyledCol span={8}>
            <StyledCard>
              <ApprovedCountFilterFormItem form={form} value={query.filter?.approvedCount} />
            </StyledCard>
          </StyledCol>
        )}

        {selectedFilters.includes('issueDate') && (
          <StyledCol span={8}>
            <StyledCard>
              <IssueDateFilterFormItem form={form} value={query.filter?.issueDate} />
            </StyledCard>
          </StyledCol>
        )}

        {selectedFilters.includes('accountDate') && (
          <StyledCol span={8}>
            <StyledCard>
              <AccountDateFilterFormItem form={form} value={query.filter?.accountDate} />
            </StyledCard>
          </StyledCol>
        )}

        {selectedFilters.includes('dueDate') && (
          <StyledCol span={8}>
            <StyledCard>
              <DayAgingFilterFormItem form={form} value={query.filter?.dueDate} />
            </StyledCard>
          </StyledCol>
        )}

        {selectedFilters.includes('total') && (
          <StyledCol span={8}>
            <StyledCard>
              <TotalValueFilterFormItem form={form} value={query.filter?.total} />
            </StyledCard>
          </StyledCol>
        )}

        {selectedFilters.includes('paymentPaid') && (
          <StyledCol span={8}>
            <StyledCard>
              <PaymentPaidFilterFormItem form={form} value={query.filter?.paymentPaid} />
            </StyledCard>
          </StyledCol>
        )}

        {selectedFilters.includes('bookingUuid') && (
          <StyledCol span={8}>
            <StyledCard>
              <BookingNoFilterFormItem
                // @ts-ignore
                form={form}
                value={query.filter?.bookingUuid}
              />
            </StyledCard>
          </StyledCol>
        )}

        {selectedFilters.includes('isCreditNote') && (
          <StyledCol span={8}>
            <StyledCard>
              <CreditNoteFilterFormItem form={form} value={query.filter?.isCreditNote} />
            </StyledCard>
          </StyledCol>
        )}

        {selectedFilters.includes('isJournalVoucher') && (
          <StyledCol span={8}>
            <StyledCard>
              <JournalVoucherFilterFormItem form={form} value={query.filter?.isJournalVoucher} />
            </StyledCard>
          </StyledCol>
        )}
      </Row>

      <Divider className="my-4" />

      <div className="flex justify-end gap-8">
        {!isTransport && !isPortalUser && (
          <Button onClick={handleApSubmittedHaulage}>AP submitted - haulage</Button>
        )}

        {!isPortalUser && (
          <div className="flex gap-8">
            <Button onClick={handleArNeedsFinApproval}>AR that needs Fin. approval</Button>
            <Button onClick={handleArNeedsApproval}>AR that needs approval</Button>
            <Divider type="vertical" className="h-full" />
          </div>
        )}

        <Button onClick={handleClearAll}>Clear All</Button>

        <Button type="primary" onClick={handleSearch}>
          Search
        </Button>
      </div>
    </Form>
  )
}

export default Form.create<VoucherFilterFormProps>()(VoucherFilterForm)
