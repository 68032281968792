import { Component } from 'react'
import { Collapse, Form, Radio, Tabs } from 'antd'
import { Button, Divider, Flex, Input, Select } from 'antd-v5'
import { pickBy } from 'lodash'

import DateRangePicker from '@/components/Form/DateRangePicker'
import { statuses } from '@/utils/consolVoucher'

const Panel = Collapse.Panel
const TabPane = Tabs.TabPane
const FormItem = Form.Item
const RadioGroup = Radio.Group

const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px'
}

const ConsolVoucherFilter = class extends Component {
  handleClearAll = () => {
    const { onSearch, form } = this.props
    form.resetFields()
    onSearch({ input: {} })
  }

  handleSearch = () => {
    const { form, onSearch } = this.props

    form.validateFields(async (err, values) => {
      if (err) return

      const input = pickBy(values)
      if (input.issueDate) {
        input.issueDate = pickBy(values.issueDate)
      }

      onSearch({
        input,
        limit: 20,
        offset: 0
      })
    })
  }

  render() {
    const {
      form: { getFieldDecorator }
    } = this.props

    const selectOptions = statuses.map(status => ({ label: status, value: status }))

    return (
      <Form>
        <Collapse defaultActiveKey={['1']}>
          <Panel header="Filter" key="1">
            <Tabs defaultActiveKey="type" tabPosition="left" size="small">
              <TabPane tab="Type" key="type">
                <FormItem label="Type">
                  {getFieldDecorator('type')(
                    <RadioGroup>
                      <Radio style={radioStyle}>Both AR and AP</Radio>
                      <Radio style={radioStyle} value="ConsolARInvoice">
                        AR only
                      </Radio>
                      <Radio style={radioStyle} value="ConsolAPInvoice">
                        AP only
                      </Radio>
                    </RadioGroup>
                  )}
                </FormItem>
              </TabPane>
              <TabPane tab="Status" key="status">
                <FormItem label="Status">
                  {getFieldDecorator('status')(
                    <Select mode="multiple" placeholder="Please select" options={selectOptions} />
                  )}
                </FormItem>
              </TabPane>

              <TabPane tab="Issue Date" key="issueDate">
                <FormItem label="Issue Date">
                  {getFieldDecorator('issueDate')(<DateRangePicker />)}
                </FormItem>
              </TabPane>

              <TabPane tab="Reference" key="reference">
                <FormItem label="Reference">{getFieldDecorator('reference')(<Input />)}</FormItem>
              </TabPane>
            </Tabs>

            <Divider style={{ margin: '16px 0' }} />

            <Flex justify="end" gap="middle">
              <Button onClick={this.handleClearAll}>Clear All</Button>
              <Button type="primary" onClick={this.handleSearch}>
                Search
              </Button>
            </Flex>
          </Panel>
        </Collapse>
      </Form>
    )
  }
}

export default Form.create()(ConsolVoucherFilter)
