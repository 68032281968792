import { create } from 'zustand'
import { persist } from 'zustand/middleware'

import createSelectors from '@/store/createSelectors'

type ReportState = {
  selectedReportEmbeddedUrl: string | undefined
  setSelectedReportEmbeddedUrl: (report: string | undefined) => void

  reportUuid: string | undefined
  setReportUuid: (uuid: string | undefined) => void
}

export const useReportStore = createSelectors(
  create<ReportState>()(
    persist(
      set => ({
        selectedReportEmbeddedUrl: undefined,
        setSelectedReportEmbeddedUrl: report => set({ selectedReportEmbeddedUrl: report }),

        reportUuid: undefined,
        setReportUuid: uuid => set({ reportUuid: uuid })
      }),
      {
        name: 'report-store'
      }
    )
  )
)
