import { gql } from '@/types'

export const EMPTY_TRIPS = gql(`
  query emptyTripsMain($input: EmptyTripQuery) {
    emptyTrips(input: $input) {
      rows {
        legUuid
        ownerUuid
        bookingUuid
        legUuid
        jobNo
        from
        to
        status
        remarks
        billToSource
        shipperRequiredDate
        billToName
        billToUuid
        shipperName
        shipperUuid
        consigneeName
        consigneeUuid
        transportSource
        driverUuid
        driverCode
        driverName
        vehicleUuid
        vehicleCode
        vehicleName
        vehicleDepartments
        trailerUuid
        trailerCode
        trailerName
        planStart
        lastTs
      }
      pageInfo {
        count
        limit
        offset
      }
    }
  }
`)

export const EMPTY_TRIP_QUERY = gql(`
  query emptyTripModal($uuid: UUID) {
    emptyTrip(uuid: $uuid) {
      ownerUuid
      bookingUuid
      legUuid
      jobNo
      from
      to
      status
      # reason
      remarks
      cost
      shipperRequiredDate
      planStart
      shipperName
      shipperUuid
      consigneeName
      consigneeUuid
      transportSource
      driverUuid
      driverCode
      driverName
      vehicleUuid
      vehicleCode
      vehicleName
      trailerUuid
      trailerCode
      trailerName
    }
  }
`)

export const PRECOMPUTE_EMPTY_TRIP_QUERY = gql(`
  query precomputeEmptyTrip1($uuid: UUID) {
    precomputeEmptyTrip(uuid: $uuid)
  }
`)

export const ADD_EMPTY_TRIP = gql(`
  mutation addEmptyTrip1($input: EmptyTripAddInput) {
    addEmptyTrip(input: $input) {
      success
      message
    }
  }
`)

export const EMPTY_TRIP_UPDATE = gql(`
  mutation updateEmptyTrip1($input: EmptyTripUpdateInput) {
    updateEmptyTrip(input: $input) {
      success
      message
    }
  }
`)

export const EMPTY_TRIP_CHANGE_STATUS = gql(`
  mutation updateEmptyTripStatus1($input: EmptyTripUpdateStatusInput) {
    updateEmptyTripStatus(input: $input) {
      success
      message
    }
  }
`)

enum EmptyTripSettingKey {
  CANCEL = 'plannerEmptyTripCancelReason',
  VALIDATE = 'plannerEmptyTripValidateReason'
}

export const EMPTY_TRIPS_VALIDATE_SETTINGS = gql(`
  query setting {
    setting(key: "${EmptyTripSettingKey.VALIDATE}") {
      key
      setting {
        value
      }
    }
  }
`)

export const EMPTY_TRIPS_CANCEL_SETTINGS = gql(`
  query setting2 {
    setting(key: "${EmptyTripSettingKey.CANCEL}") {
      key
      setting {
        value
      }
    }
  }
`)

export const JOB_SUMMARIES_QUERY = gql(`
  query jobSummariesMain($input: JobSummariesQueryInput) {
    jobSummaries(input: $input) {
      rows {
        date
        baseCompanyUuid
        from
        to
        ladenCount
        emptyCount
        emptyCost
        transportSource
        dept
      }
      pageInfo {
        offset
        limit
        count
      }
    }
  }
`)

export const TRIP_SUMMARIES_QUERY = gql(`
  query tripSummariesMain($input: TripSummariesQueryInput) {
    tripSummaries(input: $input) {
      rows {
        date
        baseCompanyUuid
        from
        to
        ladenCount
        emptyCount
        emptyCost
        transportSource
        dept
      }
      pageInfo {
        offset
        limit
        count
      }
    }
  }
`)

export const LEG_SUMMARIES_QUERY = gql(`
  query legSummariesMain($input: LegSummariesQueryInput) {
    legSummaries(input: $input) {
      rows {
        date
        baseCompanyUuid
        from
        to
        ladenCount
        emptyCount
        emptyCost
        transportSource
        dept
      }
      pageInfo {
        offset
        limit
        count
      }
    }
  }
`)

export const TRANSPORT_JOBS_QUERY = gql(`
  query transportJobsMain($input: TransportJobQuery!) {
    transportJobs(input: $input) {
      rows {
        uuid
        ownerUuid
        bookingUuid
        jobUuid
        tripUuid
        legUuid

        from
        to
        jobType
        tripType
        tripSequence
        tripStatus
        legStatus
        sequence
        shipperName
        consigneeName
        billToName
        billToUuid
        billToSource
        shipDept
        conDept
        dept
        no
        jobNo
        tripType
        shipperRequiredDate

        bookingDetails

        planStart
        start
        startOut
        planEnd
        end
        endOut
        lastTs

        driverCode
        driverName
        driverUuid

        vehicleCode
        vehicleName
        vehicleUuid
        trailerCode
        trailerName
        trailerUuid
        transportUuid
        transportName
        transportSource
        remarks

        toCompanyUuid
        fromCompanyUuid
      }

      pageInfo {
        limit
        offset
        count
      }
    }
  }
`)
export const TRANSPORT_JOBS_QUERY_BY_VEHICLE = gql(`
  query transportJobsByVehicle($input: TransportJobQuery!) {
    transportJobs(input: $input) {
      rows {
        uuid
        ownerUuid
        bookingUuid
        jobUuid
        tripUuid
        legUuid

        from
        to
        jobType
        tripType
        tripSequence
        tripStatus
        legStatus
        sequence
        shipperName
        consigneeName
        billToName
        billToUuid
        billToSource
        shipDept
        conDept
        dept
        no
        jobNo

        tripType

        shipperRequiredDate

        bookingDetails

        planStart
        start
        startOut
        planEnd
        end
        endOut
        lastTs

        driverCode
        driverName
        driverUuid
        vehicleCode
        vehicleName
        vehicleUuid
        vehicleDepartments
        trailerCode
        trailerName
        trailerUuid
        transportUuid
        transportName
        transportSource
        remarks
      }

      pageInfo {
        limit
        offset
        count
      }
    }
  }
`)

export const ADD_JOB_TRANSPORT_JOBS_QUERY = gql(`
  query transportJobsAddJobModal($input: TransportJobQuery!) {
    transportJobs(input: $input) {
      rows {
        uuid
        ownerUuid
        bookingUuid
        jobUuid
        tripUuid
        legUuid

        from
        to
        jobType
        tripType
        tripSequence
        tripStatus
        legStatus
        sequence
        shipperName
        consigneeName
        billToName
        billToUuid
        billToSource
        shipDept
        conDept
        dept
        no
        jobNo
        tripType
        shipperRequiredDate

        bookingDetails
        jobDetails
        stat
        lStat

        planStart
        start
        startOut
        planEnd
        end
        endOut
        lastTs

        driverCode
        driverName
        driverUuid
        vehicleCode
        vehicleName
        vehicleUuid
        vehicleDepartments
        trailerCode
        trailerName
        trailerUuid
        transportUuid
        transportName
        transportSource
        remarks
      }

      pageInfo {
        limit
        offset
        count
      }
    }
  }
`)

export const TRIP_AND_LEGS_QUERY = gql(`
  query tripAndLegs1($input: TransportJobQuery!) {
    transportJobs(input: $input) {
      rows {
        uuid
        jobUuid
        tripUuid
        legUuid
        ownerUuid
        jobDetails
        jobType
        from
        to
        tripStatus
        tripType
        tripSeal
        tripSequence
        legStatus
        sequence
        shipperName
        bookingDetails
        bookingNo
        shipperAddress {
          uuid
          name
          address1
          address2
          address3
          address4
          city
          district
          postCode
          plusCode
          placeId
          areaCode
          zone
          phone
          tags
          location {
            type
            coordinates
          }
          countryAlpha3
        }
        consigneeName
        consigneeAddress {
          uuid
          name
          address1
          address2
          address3
          address4
          city
          district
          postCode
          plusCode
          placeId
          areaCode
          zone
          phone
          tags
          location {
            type
            coordinates
          }
          countryAlpha3
        }
        billToName
        no
        jobNo

        shipperRequiredDate
        planStart
        start
        startOut
        planEnd
        end
        endOut

        driverCode
        driverName
        driverUuid
        driverEntity {
          code
          name
          uuid
          contact {
            type
            number
          }
          registration
        }
        driverIc
        driverPhoneNo
        assistantUuids
        assistants {
          name
          uuid
          code
        }
        vehicleCode
        vehicleName
        vehicleUuid
        trailerCode
        trailerName
        trailerUuid
        transportUuid
        transportName
        transportSource
        remarks
      }

      pageInfo {
        limit
        offset
        count
      }
    }
  }
`)

export const LEG_ACTIVITIES_QUERY = gql(`
  query legActivitiesLog($input: LegActivitiesQuery!) {
    legActivitiesJson(input: $input)
  }
`)

export const PROCESS_VEHICLE_PLAN_QUERY = gql(`
  query processVehiclePlan(
    $vehicleUuid: UUID
    $baseCompanyUuid: UUID
    $start: DateTime
    $end: DateTime
    $describe: Boolean
  ) {
    processVehiclePlan(
      vehicleUuid: $vehicleUuid
      baseCompanyUuid: $baseCompanyUuid
      start: $start
      end: $end
      describe: $describe
    )
  }
`)

export const DRIVERS_QUERY = gql(`
  query drivers1(
    $q: String
    $statuses: [DriverStatus]
    $uuids: [UUID]
    $companyUuids: [String]
    $customerUuids: [String]
    $subContractorUuids: [String]
    $limit: Int
    $offset: Int
  ) {
    drivers(
      q: $q
      statuses: $statuses
      uuids: $uuids
      companyUuids: $companyUuids
      customerUuids: $customerUuids
      subContractorUuids: $subContractorUuids
      limit: $limit
      offset: $offset
    ) {
      rows {
        uuid
        name
        code
        department
        contact {
          type
          number
        }
        vehiclePreference
      }
    }
  }
`)

export const DRIVER_QUERY = gql(`
  query driver1($uuid: UUID!) {
    driver(uuid: $uuid) {
      uuid
      name
      code
      department
      ownerType
    }
  }
`)

export const VEHICLES_QUERY = gql(`
  query vehicles1(
    $q: String
    $statuses: [VehicleStatus]
    $uuids: [UUID]
    $companyUuids: [String]
    $customerUuids: [String]
    $subContractorUuids: [String]
    $limit: Int
    $offset: Int
  ) {
    vehicles(
      q: $q
      statuses: $statuses
      uuids: $uuids
      companyUuids: $companyUuids
      customerUuids: $customerUuids
      subContractorUuids: $subContractorUuids
      limit: $limit
      offset: $offset
    ) {
      rows {
        uuid
        registration
        code
        department
        driverPreference
        trailerPreference
        miscFieldTypes {
          tags
          key
          status
        }
        miscFields
      }
    }
  }
`)

export const TRAILERS_QUERY = gql(`
  query trailers1(
    $q: String
    $statuses: [TrailerStatus]
    $companyUuids: [String]
    $uuids: [UUID]
    $customerUuids: [String]
    $subContractorUuids: [String]
    $limit: Int
    $offset: Int
  ) {
    trailers(
      q: $q
      statuses: $statuses
      uuids: $uuids
      companyUuids: $companyUuids
      customerUuids: $customerUuids
      subContractorUuids: $subContractorUuids
      limit: $limit
      offset: $offset
    ) {
      rows {
        uuid
        registration
        code
        department
         miscFieldTypes {
          tags
          key
          status
        }
        miscFields
      }
    }
  }
`)

export const VEHICLE_QUERY = gql(`
  query vehicle1($uuid: UUID!) {
    vehicle(uuid: $uuid) {
      uuid
      registration
      code
      department
      ownerType
    }
  }
`)

export const ADD_LEG_MUTATION = gql(`
  mutation addLeg1($input: AddLegInput!) {
    addLeg(input: $input) {
      success
    }
  }
`)

export const UPDATE_LEG_MUTATION = gql(`
  mutation updateLegMain1($input: UpdateLegInput!) {
    updateLeg(input: $input) {
      success
      message
    }
  }
`)

export const UPDATE_LEG_TIMING_MUTATION = gql(`
  mutation updateLegTiming1($input: UpdateLegTimingInput!) {
    updateLegTiming(input: $input) {
      success
      message
    }
  }
`)

export const DELETE_LEG_MUTATION = gql(`
  mutation deleteLeg1($input: DeleteLegInput) {
    deleteLeg(input: $input) {
      success
    }
  }
`)

export const UNDELETE_LEG_MUTATION = gql(`
  mutation undeleteLeg1($input: DeleteLegInput) {
    undeleteLeg(input: $input) {
      success
    }
  }
`)

export const BASECOMPANIES_QUERY = gql(`
  query baseCompanies2 {
    baseCompanies {
      rows {
        uuid
        name
        shortCode
        types
      }
    }
  }
`)

export const ENTITIES_VEHICLE_QUERY = gql(`
  query vehicles2($uuids: [UUID], $limit: Int) {
    vehicles(uuids: $uuids, limit: $limit) {
      rows {
        uuid
        subContractors {
          companyReference
          companyUuid
        }
      }
    }
  }
`)

export const COMPANIES_QUERY = gql(`
  query company($uuid: UUID!) {
    company(uuid: $uuid) {
      name
      accountUuid
    }
  }
`)

export const INCENTIVE_VOUCHERS_QUERY = gql(`
  query incentiveVouchers1($input: IncentiveVoucherQueryInput!) {
    incentiveVouchers(input: $input) {
      rows {
        _id
        voucherNo
        date
        status
        amount
        remarks
        incentiveCategorySum {
          category
          amount
        }
        incentives {
          # TODO (api): incentiveCategorySum is sometimes null
          amount
          type {
            category
          }
        }
        drivers {
          name
        }
      }
      pageInfo {
        count
        limit
        offset
      }
    }
  }
`)

export const APPROVE_SHIFT_MUTATION = gql(`
  mutation approveShift($uuid: UUID!) {
    approveShift(uuid: $uuid) {
      success
    }
  }
`)

export const UNAPPROVE_SHIFT_MUTATION = gql(`
  mutation unapproveShift($uuid: UUID!) {
    unapproveShift(uuid: $uuid) {
      success
    }
  }
`)

export const APPROVE_SHIFT_FINANCE_MUTATION = gql(`
  mutation approveShiftFinance($uuid: UUID!) {
    approveShiftFinance(uuid: $uuid) {
      success
    }
  }
`)

export const UNAPPROVE_SHIFT_FINANCE_MUTATION = gql(`
  mutation unapproveShiftFinance($uuid: UUID!) {
    unapproveShiftFinance(uuid: $uuid) {
      success
    }
  }
`)

export const CANCEL_SHIFT_MUTATION = gql(`
  mutation cancelShift($uuid: UUID!) {
    cancelShift(uuid: $uuid) {
      success
    }
  }
`)

export const UNCANCEL_SHIFT_MUTATION = gql(`
  mutation uncancelShift($uuid: UUID!) {
    uncancelShift(uuid: $uuid) {
      success
    }
  }
`)

export const UNDELETE_INCENTIVE_MUTATION = gql(`
  mutation undeleteIncentive1($uuid: UUID!) {
    undeleteIncentive(uuid: $uuid) {
      success
    }
  }
`)

export const INCENTIVES_QUERY = gql(`
  query incentives1($input: IncentiveQueryInput!) {
    incentives(input: $input) {
      rows {
        _id
        # shiftUuid
        # ownerUuid
        driverUuid
        driverCode
        driverName
        incentiveVoucherUuid
        incentiveVoucher {
          # _id
          voucherNo
        }
        # jobUuid
        shift {
          # _id
          no
        }
        status
        date
        remarks
        amount
        # createdAt
        updatedAt
        type {
          # _id
          # code
          name
          category
        }
        transportJob {
          # _id
          bookingUuid
          # legUuid
          # no
          jobNo
          tripSequence
          tripType
          sequence
          # bookingNo
          shipperName
          consigneeName
        }
      }
      pageInfo {
        count
        limit
        offset
      }
    }
  }
`)
export const INCENTIVE_QUERY = gql(`
  query incentive2($uuid: UUID!) {
    incentive(uuid: $uuid) {
      _id
      # ownerUuid

      driverUuid
      # driver { name code uuid }
      date

      status
      type {
        _id
        # code
        # name
      }
      # costCode

      transportJob {
        legUuid
        # no
        # bookingNo
      }

      remarks
      amount

      # createdAt
      # createdBy { name email }

      # updatedAt
      # updatedBy { name email }
    }
  }
`)

export const UPDATE_INCENTIVE_MUTATION = gql(`
  mutation updateIncentive($input: UpdateIncentiveInput) {
    updateIncentive(input: $input) {
      _id
    }
  }
`)

export const DELETE_INCENTIVE_MUTATION = gql(`
  mutation deleteIncentive1($uuid: UUID!) {
    deleteIncentive(uuid: $uuid) {
      success
    }
  }
`)

export const incentivesForVoucherFragment = gql(`
  fragment incentivesForVoucherFragment on Incentive {
    _id
    amount
    date
    driverUuid
    driver {
      name
      code
    }
    incentiveVoucherUuid
    incentiveVoucher {
      voucherNo
    }
    remarks
    shift {
      no
    }
    status
    transportJob {
      bookingUuid
      jobNo
      consigneeName
      shipperName
    }
    type {
      category
      code
      name
    }
    vehicleUuid
    vehicle {
      registration
      code
    }
  }
`)

export const INCENTIVES_FOR_VOUCHER_QUERY = gql(`
  query incentivesForVoucher2($input: IncentiveQueryInput!) {
    incentives(input: $input) {
      rows {
        ...incentivesForVoucherFragment
      }
      pageInfo {
        count
        limit
        offset
      }
    }
  }
`)

export const incentiveVoucherFragment = gql(`
  fragment incentiveVoucherFragment on IncentiveVoucher {
    _id
    amount
    approvedAt
    approvedBy {
      email
    }
    approvedUuid
    approvedFinanceAt
    approvedFinanceBy {
      email
    }
    approvedFinanceUuid
    date
    driverUuids
    incentiveCategorySum {
      category
      amount
    }
    incentiveTypeSum {
      typeUuid
      amount
    }
    incentiveUuids
    remarks
    status
    vehicleUuids
    voucherNo
  }
`)

export const INCENTIVE_VOUCHER_QUERY = gql(`
  query incentiveVoucher1 ($uuid: UUID) {
    incentiveVoucher(uuid: $uuid) {
      ...incentiveVoucherFragment
      incentives {
        ...incentivesForVoucherFragment
      }
    }
  }
`)

export const CREATE_INCENTIVE_VOUCHER_MUTATION = gql(`
  mutation createIncentiveVoucher1 ($input: CreateIncentiveVoucherInput!) {
    createIncentiveVoucher (input: $input) {
      ...incentiveVoucherFragment
      incentives {
        ...incentivesForVoucherFragment
      }
    }
  }
`)

export const UPDATE_INCENTIVE_VOUCHER_MUTATION = gql(`
  mutation updateIncentiveVoucher1 ($input: UpdateIncentiveVoucherInput!) {
    updateIncentiveVoucher (input: $input) {
      ...incentiveVoucherFragment
      incentives {
        ...incentivesForVoucherFragment
      }
    }
  }
`)

export const APPROVE_INCENTIVE_VOUCHER_MUTATION = gql(`
  mutation approveIncentiveVoucher1($uuid: UUID) {
    approveIncentiveVoucher(uuid: $uuid) {
      success
    }
  }
`)

export const UNAPPROVE_INCENTIVE_VOUCHER_MUTATION = gql(`
  mutation unapproveIncentiveVoucher1($uuid: UUID!) {
    unapproveIncentiveVoucher(uuid: $uuid) {
      success
    }
  }
`)

export const FINANCE_APPROVE_INCENTIVE_VOUCHER_MUTATION = gql(`
  mutation approveIncentiveVoucherFinance1($uuid: UUID!) {
    approveIncentiveVoucherFinance(uuid: $uuid) {
      success
    }
  }
`)

export const FINANCE_UNAPPROVE_INCENTIVE_VOUCHER_MUTATION = gql(`
  mutation unapproveIncentiveVoucherFinance1($uuid: UUID!) {
    unapproveIncentiveVoucherFinance(uuid: $uuid) {
      success
    }
  }
`)

export const CANCEL_INCENTIVE_VOUCHER_MUTATION = gql(`
  mutation cancelIncentiveVoucher1($uuid: UUID!) {
    cancelIncentiveVoucher(uuid: $uuid) {
      success
    }
  }
`)

export const UNCANCEL_INCENTIVE_VOUCHER_MUTATION = gql(`
  mutation uncancelIncentiveVoucher1($uuid: UUID!) {
    uncancelIncentiveVoucher(uuid: $uuid) {
      success
    }
  }
`)

export const INCENTIVE_VOUCHER_JSON = `
  query incentiveVouchersJson1 ($input: IncentiveVoucherQueryInput!) {
    incentiveVouchersJson (input: $input)
  }
`

export const QUERY_AVAILABILITIES = gql(`
  query vehicleAvailabilities2($input: QueryAvailabilitiesInput!) {
    availabilities(input: $input) {
      rows {
        date
        details {
          entityUuid
          vehicle {
            uuid
            registration
            code
            department
          }
        }
        type
        status
        count
        baseCompanyUuid
        uuid
      }
    }
  }
`)

export const CREATE_MANIFEST = gql(`
  mutation addManifest($input: AddManifestInput) {
    addManifest(input: $input) {
      uuid
      no
    }
  }
`)

export const GET_MANIFESTS = gql(`
  query getManifests($input: QueryManifestsInput) {
    manifests(input: $input) {
      rows {
        no
        uuid
        driverUuid
        driver {
          name
          code
        }
        vehicleUuid
        vehicle {
          code
          registration
        }
        startTime
        endTime
        startLocation {
          uuid
          name
        }
        endLocation {
          uuid
          name
        }
        status
      }
      count
    }
  }
`)

export const GET_MANIFEST = gql(`
  query getManifest($uuid: UUID) {
    manifest(uuid: $uuid) {
      no
      uuid
      driverUuid
      driver {
        name
        code
      }
      vehicleUuid
      vehicle {
        code
        registration
      }
      status
      legs {
        jobNo
        legUuid
        legStatus
        tripUuid
        start
        startOut
        end
        endOut
        to
        from
      }
      startTime
      endTime
      startLocation {
        uuid
        name
      }
      endLocation {
        uuid
        name
      }
    }
  }
`)

export const GET_JOBS_FOR_MANIFEST = gql(`
  query getTransportJobs($input: TransportJobQuery!) {
    transportJobs(input: $input) {
      rows {
        _id
        bookingUuid
        legStatus
        legUuid
        jobUuid
        jobStatus
        jobNo
        from
        to
        shipperRequiredDate
        shipperName
        consigneeName
        start
        startOut
        end
        endOut
        type
        tripUuid
        tripType
        driverName
        driverCode
        sequence
        sequenceTotal
      }
      pageInfo {
        count
        limit
        offset
      }
    }
  }
`)

export const ADD_LEGS_TO_MANIFEST = gql(`
  mutation addLegs($input: AddManifestLegsInput) {
    addLegsToManifest(input: $input) {
      message
      success
    }
  }
`)

export const REMOVE_LEG_FROM_MANIFEST = gql(`
  mutation removeLeg($input: RemoveManifestLegsInput) {
    removeLegsFromManifest(input: $input) {
      message
      success
    }
  }
`)

export const DELETE_MANIFESTS = gql(`
  mutation deleteManifests($uuids: [UUID]) {
    deleteManifests(uuids: $uuids) {
      success
    }
  }
`)

export const CLOSE_MANIFESTS = gql(`
  mutation closeManifests($uuids: [UUID]) {
    closeManifests(uuids: $uuids) {
      success
    }
  }
`)

export const GET_DOCUMENT_CREATOR_TEMPLATE = gql(`
  query DocumentCreatorTemplates(
    $q: String
    $qs: [String]
    $statuses: [DocumentCreatorTemplateStatus]
  ) {
    documentCreatorTemplates(q: $q, qs: $qs, statuses: $statuses) {
      rows {
        name
        type
        uuid
      }
    }
  }
`)

export const GET_TIMING_ESTIMATE = gql(`
  query GetTimingEstimate($legUuid: UUID) {
    getTimingEstimate(legUuid: $legUuid) {
      start
      startOut
      end
      endOut
    }
  }
`)

export const TRIGGER_ESTIMATE_TIMING_SINGLE = gql(`
  query TriggerEstimateTimingSingle($legUuid: UUID) {
    triggerEstimateTimingSingle(legUuid: $legUuid) {
      success
    }
  }
`)
