import type { GetBaseCompanyTypesAssQuery } from '@/types/graphql'
import { gql } from '@/types'

import { useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import capitalize from 'lodash/capitalize'

import { LOCAL_STORAGE_KEYS } from '@/utils/auth'
import responseHandler from '@/utils/responseHandler'
import webStorage from '@/utils/webStorage'

const BASECOMPANIES_TYPES_QUERY = gql(`
  query getBaseCompanyTypesAss {
    getBaseCompanyTypes {
      key
      value
      meta
    }
  }
`)

const useBaseCompanyType = () => {
  const [companyTypes, setCompanyTypes] = useState<
    NonNullable<GetBaseCompanyTypesAssQuery>['getBaseCompanyTypes']
  >(webStorage.getItem(LOCAL_STORAGE_KEYS.BASE_COMPANY_TYPES) || [])

  const [getBcTypes, { loading, data, error }] = useLazyQuery(BASECOMPANIES_TYPES_QUERY)

  if (error) {
    responseHandler(error, 'error')
  }

  useEffect(() => {
    if (!companyTypes?.length) getBcTypes()
  }, [])

  useEffect(() => {
    if (data?.getBaseCompanyTypes) {
      const bcTypes = data.getBaseCompanyTypes
        .filter(type => !['FINANCE'].includes(type?.meta?.[0]))
        .map(type => ({
          key: type?.key,
          label: capitalize(type?.key || ''),
          meta: type?.meta || {},
          category: `${type?.meta?.type?.toLowerCase()}Categories`
        }))

      setCompanyTypes(bcTypes)
      webStorage.setItem(LOCAL_STORAGE_KEYS.BASE_COMPANY_TYPES, bcTypes)
    }
  }, [data])

  return {
    loading,
    data: companyTypes
  }
}

export default useBaseCompanyType
