import { useState } from 'react'
import { useAsyncDebounce } from 'react-table'
import styled from 'styled-components'

const InputWrapper = styled.input`
  border: 1px solid LightGrey;
  border-radius: 3px;
  width: 100%;
`

export const ColumnFilter = ({ column }: any) => {
  const { filterValue, setFilter } = column
  const [value, setvalue] = useState(filterValue)

  const onChange = useAsyncDebounce((value: any) => {
    setFilter(value || undefined)
  }, 400)

  return (
    <InputWrapper
      value={value || ''}
      onChange={(e: any) => {
        setvalue(e.target.value)
        onChange(e.target.value)
      }}
    />
  )
}
