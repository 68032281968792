import React, { useCallback, useState } from 'react'
import { CloseCircleOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons'
import { Button, Col, Flex, Input } from 'antd-v5'
import { debounce } from 'lodash'

import { ActionWrapper } from '@/components/Manage/Employees/TableView/Actions/Styled'
import ImportExport from '@/components/Shared/ImportExport'

interface TableViewActionsProps {
  updateSearchKeyword: (keyword: string) => void
  updatePageSize: (size: number) => void
  filter: unknown
}

const TableViewActions: React.FC<TableViewActionsProps> = ({
  updateSearchKeyword,
  updatePageSize,
  filter
}) => {
  const [keyword, setKeyword] = useState('')

  const debouncedSearch = useCallback(
    debounce((value: string) => {
      updateSearchKeyword(value)
      updatePageSize(0)
    }, 300),
    [updateSearchKeyword, updatePageSize]
  )

  const handleInput = (value: string) => {
    setKeyword(value)
    debouncedSearch(value)
  }

  const suffix = keyword ? <CloseCircleOutlined onClick={() => handleInput('')} /> : null

  return (
    <ActionWrapper>
      <Flex justify="space-around" align="center">
        <Col span={12}>
          <Input
            value={keyword}
            prefix={<SearchOutlined />}
            suffix={suffix}
            placeholder="Search something here..."
            onChange={e => handleInput(e.target.value)}
          />
        </Col>
        <Col span={12}>
          <Flex justify="justify-end">
            <Col span={6}>
              <ImportExport selectedEntity="employees" filter={filter} />
            </Col>
            <Col span={6}>
              <Button href="/manage/employees/create">
                <PlusOutlined />
                Create
              </Button>
            </Col>
          </Flex>
        </Col>
      </Flex>
    </ActionWrapper>
  )
}

export default TableViewActions
