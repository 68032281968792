import { CompanyInviteStatus } from '@/types/graphql'

import { memo, useCallback, useState } from 'react'
import { Row, Skeleton } from 'antd-v5'

import Link from '@/components/Link'
import TableActions from '@/components/Manage/Employees/TableView/Actions'
import RolesFilter from '@/components/Manage/Employees/TableView/Filters/Roles'
import { EMPLOYEES_QUERY } from '@/components/Manage/Employees/TableView/schema'
import PageHeader from '@/components/Manage/PageHeader'
import StatusFilter from '@/components/Manage/Shared/CrudType/TableView/StatusFilter'
import { PageView, ViewContent } from '@/components/Manage/Styled'
import NoAccess from '@/components/Shared/NoAccess'
import TableView from '@/components/TableView'
import { NormalText, StyledAnchor } from '@/components/TableView/Styled'
import WithSearchAndPagination from '@/components/TableView/WithSearchAndPagination'
import usePermissionIsAllowed from '@/hooks/usePermissionIsAllowed'
import useGlobalCompanyStore from '@/store/globalCompany'

const EmployeesTableView = () => {
  const selectedGlobalCompany = useGlobalCompanyStore.use.selectedGlobalCompany()

  const { loading: permLoading, hasPermission } = usePermissionIsAllowed({
    resource: 'employee',
    permission: 'index'
  })
  const [filter, setFilter] = useState({
    roles: []
  })

  const renderColumns = useCallback(() => {
    const columns = [
      {
        title: 'Email',
        key: 'email',
        render: (text, record) => (
          <Link to={`/manage/employees/${record.uuid}`}>
            <StyledAnchor>{record.user?.email || '-'}</StyledAnchor>
          </Link>
        )
      },
      {
        title: 'Name',
        key: 'name',
        render: (text, record) => <NormalText>{record.user?.name || '-'}</NormalText>
      },
      {
        title: 'Roles',
        filterDropdown: <RolesFilter filter={filter} setFilter={setFilter} />,
        key: 'roles',
        render: (text, record) => <NormalText>{record.roles?.join(', ') || '-'}</NormalText>
      },
      {
        ...StatusFilter({
          status: CompanyInviteStatus,
          isCaps: false
        })
      }
    ]

    return columns
  }, [filter])

  if (permLoading) {
    return (
      <Row style={{ marginTop: '30px' }}>
        <Skeleton active />
      </Row>
    )
  }

  if (!hasPermission) return <NoAccess />

  return (
    <WithSearchAndPagination>
      {({ currentSize, updateSize, searchKeyword, updateSearchKeyword }) => (
        <PageView>
          <PageHeader title="Employees" />
          <ViewContent>
            <TableActions
              updatePageSize={updateSize}
              updateSearchKeyword={updateSearchKeyword}
              filter={filter}
            />
            <TableView
              currentSize={currentSize}
              searchKeyword={searchKeyword}
              updatePageSize={updateSize}
              renderColumns={renderColumns}
              dataPropKey="employees"
              queryProps={{
                query: EMPLOYEES_QUERY,
                fetchPolicy: 'cache-and-network',
                variables: {
                  ...filter,
                  offset: currentSize * 20,
                  limit: 20,
                  query: searchKeyword,
                  companyUuids: [selectedGlobalCompany?.uuid]
                }
              }}
            />
          </ViewContent>
        </PageView>
      )}
    </WithSearchAndPagination>
  )
}

export default memo(EmployeesTableView)
