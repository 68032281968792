import { gql } from '@apollo/client'

export const NEW_REPORTS_QUERY = gql`
  query reportsMain($input: ReportQueryInput) {
    reports(input: $input) {
      rows {
        uuid
        name
        model
        description
        categories
        columns {
          header
          formatFnc
          formatParams
        }
      }
      pageInfo {
        count
        limit
        offset
      }
    }
  }
`

export const REPORT_QUERY = gql`
  query viewReport1($uuid: UUID) {
    report(uuid: $uuid) {
      uuid
      name
      key
      model
      description
      status
      categories
      sorts
      columns {
        key
        header
        formatFnc
        formatParams
      }
      data
    }
  }
`

export const REPORT_FIELDS_QUERY = gql`
  query getReportFields1($model: ReportModel) {
    reportFields(model: $model) {
      name
      type
    }
  }
`

export const CREATE_REPORT_MUTATION = gql`
  mutation createReport1($input: CreateReportInput) {
    createReport(input: $input) {
      success
    }
  }
`

export const UPDATE_REPORT_MUTATION = gql`
  mutation updateReport1($input: UpdateReportInput) {
    updateReport(input: $input) {
      success
    }
  }
`

export const GENERATE_REPORT_MUTATION = gql`
  mutation generateReportEmail1($input: GenerateReportInput) {
    generateReport(input: $input) {
      message
      success
    }
  }
`

export const DELETE_REPORT_MUTATION = gql`
  mutation deleteReport1($uuid: UUID) {
    deleteReport(uuid: $uuid) {
      success
    }
  }
`
