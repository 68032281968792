import { Form } from 'antd'
import { Select } from 'antd-v5'

const FormItem = Form.Item

const paymenthMethodDs = [
  {
    text: 'Cash',
    value: 'CASH'
  },
  {
    text: 'Credit',
    value: 'CREDIT'
  }
]

const PaymentMethodFilterFormItem = (props: any) => {
  const {
    form: { getFieldDecorator },
    value
  } = props

  const options = paymenthMethodDs.map(p => ({ label: p.text, value: p.value }))

  return (
    <FormItem label="Payment Type">
      {getFieldDecorator('paymentType', {
        initialValue: value
      })(<Select mode="multiple" placeholder="Please select" options={options} />)}
    </FormItem>
  )
}

export default PaymentMethodFilterFormItem
