import { ReportModel } from '@/types/graphql'

import { memo } from 'react'
import { useQuery } from '@apollo/client'
import { Row } from 'antd-v5'

import SearchFilterForm from '@/components/Dashboard/Report/SearchFilterForm'
import { NEW_REPORTS_QUERY } from '@/components/ReportsNew/schema'

const querySize = 100

const DashboardReport = memo(() => {
  const { data } = useQuery(NEW_REPORTS_QUERY, {
    variables: {
      input: {
        limit: querySize,
        offset: 0,
        modelTypes: [ReportModel.Dashboard]
      }
    }
  })

  const reports = data?.reports?.rows
  return (
    <Row style={{ minHeight: '90vh', paddingRight: 16 }}>
      <SearchFilterForm reports={reports} />
    </Row>
  )
})
export default DashboardReport
