import type { SelectProps } from 'antd-v5'

import { memo } from 'react'
import { Select } from 'antd-v5'

type SharedSelectorProps = SelectProps & {
  dataKey?: string
  dataArray: { label: string; value: string }[]
}

const SharedSelector = (props: SharedSelectorProps) => {
  const {
    dataKey,
    dataArray,
    filterOption = false,
    placeholder = 'Please select...',
    ...rest
  } = props

  const options = dataArray?.map(data => ({
    label: data.label ? data.label : dataKey ? data[dataKey] : data,
    value: dataKey ? data[dataKey] : data
  }))

  return (
    <Select {...rest} placeholder={placeholder} filterOption={filterOption} options={options} />
  )
}

export default memo(SharedSelector)
