import { graphql } from '@apollo/client/react/hoc'
import { Spin, Typography } from 'antd-v5'
import { isObject } from 'lodash'

import companyGql from './companyGql'

interface CompanyData {
  uuid: string
  name: string
}

interface CompanyGraphqlResponse {
  loading: boolean
  error?: any
  company?: CompanyData
}

interface CompanyProps {
  companyUuid?: string
  companyGraphql?: CompanyGraphqlResponse
}

const companyGraphql = graphql(companyGql, {
  name: 'companyGraphql',
  skip: (props: CompanyProps) => !props.companyUuid,
  options: (props: CompanyProps) => ({
    variables: {
      uuid: isObject(props.companyUuid) ? props.companyUuid?.uuid : props.companyUuid
    }
  })
})

const Company = (props: CompanyProps) => {
  const { companyUuid = '', companyGraphql = {} } = props
  const { loading, error, company }: any = companyGraphql

  if (!companyUuid) return <Typography.Text>Company is not selected.</Typography.Text>
  if (loading) return <Spin />
  if (error) return <Typography.Text type="danger">Error while loading company.</Typography.Text>
  if (!company) return <Typography.Text type="danger">Error: Company not found.</Typography.Text>

  return (
    <Typography.Text>
      [{company.code}] {company.name}
    </Typography.Text>
  )
}

export default companyGraphql(Company)
