import type { PaginatedCategoryAssQuery } from '@/types/graphql'
import type { TableColumnType } from 'antd-v5'
import { ActiveStatus, CategoryModel } from '@/types/graphql'

import { Flex, Typography } from 'antd-v5'
import { startCase } from 'lodash'

import CrudTypesTableView from '@/components/Manage/Shared/CrudType/TableView'
import { PAGINATED_CATEGORY } from '@/graphql/category'

type CategoryQuery = NonNullable<
  NonNullable<NonNullable<PaginatedCategoryAssQuery>['categories']>['rows']
>[number]
type CategoryQueryRecord = TableColumnType<CategoryQuery>[]

const CategoryPage = () => {
  const columns: CategoryQueryRecord = [
    {
      title: 'Code',
      dataIndex: 'code',
      render: (code, rec) => (
        <Typography.Link href={`/manage/category/${rec?._id}`}>{code}</Typography.Link>
      )
    },
    {
      title: 'Name',
      dataIndex: 'name',
      render: (name, rec) => (
        <Typography.Link href={`/manage/category/${rec?._id}`}>{name}</Typography.Link>
      )
    },
    { title: 'Description', dataIndex: 'description', render: description => description ?? '-' },
    {
      title: 'Model',
      dataIndex: 'model',
      filters: Object.values(CategoryModel).map(model => ({
        text: startCase(model),
        value: model
      })),
      onFilter: (value, record) => record?.model === value
    },
    {
      title: 'Sorting',
      dataIndex: 'sort',
      render: sort => sort ?? 0,
      sorter: (a, b) => (a?.sort ?? 0) - (b?.sort ?? 0),
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'status',
      dataIndex: 'status',
      render: status => (
        <Typography.Text className="text-xxs font-semibold" type="secondary">
          {status}
        </Typography.Text>
      ),
      filters: Object.values(ActiveStatus).map(status => ({
        text: startCase(status),
        value: status
      })),
      onFilter: (value, record) => record?.status === value
    }
  ]

  return (
    <Flex vertical>
      <CrudTypesTableView
        crudType="category"
        title="Category"
        overrideKey="categories"
        createLink="/manage/category/create"
        gqlQuery={PAGINATED_CATEGORY}
        tableColumns={columns}
      />
    </Flex>
  )
}

export default CategoryPage
