import { Route, Routes } from 'react-router-dom'

import DashboardReport from '@/components/Dashboard/Report'
import ManageLayout from '@/components/Layouts/Manage'
import SuperAdminLayout from '@/components/Layouts/SuperAdminLayout'
import WithHeader from '@/components/Layouts/WithHeader'
import CheckIn from '@/pages/auth/CheckIn'
import SignOff from '@/pages/auth/SignOff'
import UniversalSignIn from '@/pages/auth/UniversalSignIn'
import Bookings from '@/pages/bookings'
import BookingNew from '@/pages/bookings/new'
import CategoryPage from '@/pages/category'
import UpsertCategoryPage from '@/pages/category/upsert'
import Index from '@/pages/index'
import BookingTypePage from '@/pages/manage/booking-types'
import Companies from '@/pages/manage/companies'
import Employees from '@/pages/manage/employees'
import FeatureFlagsPage from '@/pages/manage/featureFlags'
import JobTypePage from '@/pages/manage/job-types'
import PermissionsPage from '@/pages/manage/permissions'
import PortalCompany from '@/pages/manage/portal-company'
import TransportDashboardPage from '@/pages/transport/TransportDashboardPage'
import Vouchers from '@/pages/vouchers'
import lazyLoad from '@/utils/retryLazyLoad'

const SignIn = lazyLoad(() => import('@/pages/auth/SignIn'))

const ResetPassword = lazyLoad(() => import('@/pages/auth/ResetPassword'))
const ActivateAccount = lazyLoad(() => import('@/pages/activate/account'))

const BookingEdit = lazyLoad(() => import('@/pages/bookings/edit'))
const BookingDocument = lazyLoad(() => import('@/pages/bookings/document'))
const BookingDuplicate = lazyLoad(() => import('@/pages/bookings/duplicate'))
const BookingCostSheet = lazyLoad(() => import('@/pages/bookings/cost-sheet'))
const BookingCoverPage = lazyLoad(() => import('@/pages/bookings/cover-page'))

const PrePlanning = lazyLoad(() => import('@/app-transport/pages/pre-planning'))
const PlanningDashboard = lazyLoad(() => import('@/app-transport/pages/plan-dashboard'))

const DocumentCreatorView = lazyLoad(() => import('@/pages/documentCreator/view'))
const DocumentsGenerated = lazyLoad(() => import('@/pages/documents-generated'))

const Dashboard = lazyLoad(() => import('@/pages/dashboard'))
const BulkBooking = lazyLoad(() => import('@/pages/newBookingBulk'))
const BulkUploadDocument = lazyLoad(() => import('@/pages/bookings/new/bulk-import-documents'))
const BulkFunctions = lazyLoad(() => import('@/pages/bulk-function'))

const ReportsLegacyView = lazyLoad(() => import('@/pages/reports/view'))
const ReportsLegacyIndex = lazyLoad(() => import('@/pages/reports/index'))

const ReportsPage = lazyLoad(() => import('@/pages/reports-new/index'))
const ViewReportPage = lazyLoad(() => import('@/pages/reports-new/view'))
const CreateReportPage = lazyLoad(() => import('@/pages/reports-new/create'))

const SuperAdminAreaIndex = lazyLoad(() => import('@/pages/superadmin/index'))
const SuperAdminsIndex = lazyLoad(() => import('@/pages/superadmin/superadmins/index'))
const SuperAdminsCreate = lazyLoad(() => import('@/pages/superadmin/superadmins/create'))
const SuperAdminBaseCompaniesView = lazyLoad(() => import('@/pages/superadmin/base-companies/view'))
const SuperAdminBaseCompaniesIndex = lazyLoad(
  () => import('@/pages/superadmin/base-companies/index')
)
const SuperAdminBaseCompaniesCreate = lazyLoad(
  () => import('@/pages/superadmin/base-companies/create')
)

const EmptyTripsPage = lazyLoad(() => import('@/pages/transport/EmptyTripsPage'))
const LegActivitiesReportPage = lazyLoad(() => import('@/pages/transport/LegActivitiesPage'))
const RolesPage = lazyLoad(() => import('@/pages/manage/roles'))

const LogsPage = lazyLoad(() => import('@/pages/manage/logs'))
const CountersPage = lazyLoad(() => import('@/pages/manage/counters'))
const CurrenciesPage = lazyLoad(() => import('@/pages/manage/currencies'))

const UserSettingsPage = lazyLoad(() => import('@/pages/manage/user-settings'))
const BaseCompanySettings = lazyLoad(() => import('@/pages/manage/base-company-settings'))

const ChargeCategoriesPage = lazyLoad(() => import('@/pages/manage/charge-categories'))
const ViewChargeCategoriesPage = lazyLoad(() => import('@/pages/manage/charge-categories/view'))
const CreateChargeCategoriesPage = lazyLoad(() => import('@/pages/manage/charge-categories/create'))

const ChargeItemsPage = lazyLoad(() => import('@/pages/manage/charge-items'))
const ViewChargeItemsPage = lazyLoad(() => import('@/pages/manage/charge-items/view'))
const CreateChargeItemsPage = lazyLoad(() => import('@/pages/manage/charge-items/create'))

const ViewPortalCompany = lazyLoad(() => import('@/pages/manage/portal-company/view'))
const CreatePortalCompany = lazyLoad(() => import('@/pages/manage/portal-company/create'))

const ViewCompanies = lazyLoad(() => import('@/pages/manage/companies/view'))
const CreateCompanies = lazyLoad(() => import('@/pages/manage/companies/create'))

const DocumentCreatorTemplatesPage = lazyLoad(
  () => import('@/pages/manage/document-creator-templates/view')
)
const DocumentCreatorTableTemplatesPage = lazyLoad(
  () => import('@/pages/manage/document-creator-templates')
)
const CreateDocumentCreatorTemplatesPage = lazyLoad(
  () => import('@/pages/manage/document-creator-templates/create')
)

const DocumentGeneratorTemplatesPage = lazyLoad(
  () => import('@/pages/manage/document-generator-templates/view')
)
const DocumentGeneratorTableTemplatesPage = lazyLoad(
  () => import('@/pages/manage/document-generator-templates')
)
const CreateDocumentGeneratorTemplatesPage = lazyLoad(
  () => import('@/pages/manage/document-generator-templates/create')
)

const PluginsPage = lazyLoad(() => import('@/pages/manage/plugins'))
const CrudPluginPage = lazyLoad(() => import('@/pages/manage/plugins/crud'))

const ViewEmployee = lazyLoad(() => import('@/pages/manage/employees/view'))
const CreateEmployee = lazyLoad(() => import('@/pages/manage/employees/create'))

const ExchangeRatesPage = lazyLoad(() => import('@/pages/manage/exchange-rates'))
const ViewExchangeRatePage = lazyLoad(() => import('@/pages/manage/exchange-rates/view'))
const CreateExchangeRatePage = lazyLoad(() => import('@/pages/manage/exchange-rates/create'))

const FafsPage = lazyLoad(() => import('@/pages/manage/fafs'))
const ViewFafPage = lazyLoad(() => import('@/pages/manage/fafs/view'))
const CreateFafPage = lazyLoad(() => import('@/pages/manage/fafs/create'))

const GlCodesPage = lazyLoad(() => import('@/pages/manage/glcodes'))
const ViewGlCodePage = lazyLoad(() => import('@/pages/manage/glcodes/view'))
const CreateGlCodePage = lazyLoad(() => import('@/pages/manage/glcodes/create'))

const BillingUnitsPage = lazyLoad(() => import('@/pages/manage/billing-units'))
const ViewBillingUnitPage = lazyLoad(() => import('@/pages/manage/billing-units/view'))
const CreateBillingUnitPage = lazyLoad(() => import('@/pages/manage/billing-units/create'))

const IntegrationDetailsPage = lazyLoad(() => import('@/pages/manage/integration-details'))
const ViewIntegrationDetailPage = lazyLoad(() => import('@/pages/manage/integration-details/view'))
const CreateIntegrationDetailsPage = lazyLoad(
  () => import('@/pages/manage/integration-details/create')
)

const IntegrationLogsPage = lazyLoad(() => import('@/pages/manage/integration-logs'))
const ViewIntegrationLogPage = lazyLoad(() => import('@/pages/manage/integration-logs/view'))

const IntegrationMappingsPage = lazyLoad(() => import('@/pages/manage/integration-mappings'))
const ViewIntegrationMappingPage = lazyLoad(
  () => import('@/pages/manage/integration-mappings/view')
)
const CreateIntegrationMappingPage = lazyLoad(
  () => import('@/pages/manage/integration-mappings/create')
)

const RatesPage = lazyLoad(() => import('@/pages/manage/rates'))
const CreateRatePage = lazyLoad(() => import('@/pages/manage/rates/create'))
const ViewRatePage = lazyLoad(() => import('@/pages/manage/rates/view'))

const HolidaysPage = lazyLoad(() => import('@/pages/manage/holidays'))
const ViewHolidayPage = lazyLoad(() => import('@/pages/manage/holidays/view'))
const CreateHolidayPage = lazyLoad(() => import('@/pages/manage/holidays/create'))

const PeriodClosingsPage = lazyLoad(() => import('@/pages/manage/period-closings'))
const ViewPeriodClosingPage = lazyLoad(() => import('@/pages/manage/period-closings/view'))
const CreatePeriodClosingPage = lazyLoad(() => import('@/pages/manage/period-closings/create'))

const CreateFeatureFlagPage = lazyLoad(() => import('@/pages/manage/featureFlags/create'))
const ViewFeatureFlagPage = lazyLoad(() => import('@/pages/manage/featureFlags/view'))

const IntegrationBulkImportFunctionsPage = lazyLoad(
  () => import('@/pages/manage/integration-bulk-import-functions')
)
const ViewIntegrationBulkImportFunctionsPage = lazyLoad(
  () => import('@/pages/manage/integration-bulk-import-functions/view')
)
const CreateIntegrationBulkImportFunctionsPage = lazyLoad(
  () => import('@/pages/manage/integration-bulk-import-functions/create')
)

const QuotationsPage = lazyLoad(() => import('@/pages/manage/quotations'))
const ViewQuotations = lazyLoad(() => import('@/pages/manage/quotations/view'))
const CreateQuotationsPage = lazyLoad(() => import('@/pages/manage/quotations/create'))

const TaxesPage = lazyLoad(() => import('@/pages/manage/taxes'))
const ViewTaxes = lazyLoad(() => import('@/pages/manage/taxes/view'))
const CreateTaxPage = lazyLoad(() => import('@/pages/manage/taxes/create'))

const TransportRatesPage = lazyLoad(() => import('@/pages/manage/transport-rates'))
const ViewTransportRatePage = lazyLoad(() => import('@/pages/manage/transport-rates/view'))
const CreateTransportRatePage = lazyLoad(() => import('@/pages/manage/transport-rates/create'))
const DuplicateTransportRatePage = lazyLoad(
  () => import('@/pages/manage/transport-rates/duplicate')
)

const TransportAreaCodesPage = lazyLoad(() => import('@/pages/manage/transport-area-codes'))
const ViewTransportAreaCodesPage = lazyLoad(
  () => import('@/pages/manage/transport-area-codes/view')
)

const VouchersView = lazyLoad(() => import('@/pages/vouchers/view'))
const VouchersAdvice = lazyLoad(() => import('@/pages/vouchers/advice'))
const VouchersSummary = lazyLoad(() => import('@/pages/vouchers/summary'))

const TransportShift = lazyLoad(() => import('@/pages/transport/shift'))
const TransportVehiclePage = lazyLoad(() => import('@/pages/transport/Vehicle'))
const TransportOperation = lazyLoad(() => import('@/pages/transport/operation/tracking'))
const TransportManifests = lazyLoad(() => import('@/pages/transport/manifests'))
const TransportPlanningPage = lazyLoad(() => import('@/pages/transport/planning'))
const TransportUnplannedPage = lazyLoad(() => import('@/pages/transport/Unplanned'))
const JobMonitoringPage = lazyLoad(() => import('@/pages/transport/JobMonitoringPage'))
const TripMonitoringPage = lazyLoad(() => import('@/pages/transport/TripMonitoringPage'))
const TransportIncentiveVoucher = lazyLoad(() => import('@/pages/transport/incentiveVoucher'))

const TransportZonePage = lazyLoad(() => import('@/pages/manage/transport-zones'))
const CreateTransportZonePage = lazyLoad(() => import('@/pages/manage/transport-zones/Create'))
const ViewTransportZonePage = lazyLoad(() => import('@/pages/manage/transport-zones/View'))

const ViewJobTypePage = lazyLoad(() => import('@/pages/manage/job-types/View'))
const CreateJobTypePage = lazyLoad(() => import('@/pages/manage/job-types/Create'))

const ViewBookingTypePage = lazyLoad(() => import('@/pages/manage/booking-types/View'))
const CreateBookingTypePage = lazyLoad(() => import('@/pages/manage/booking-types/Create'))

const IncentiveTypesPage = lazyLoad(() => import('@/pages/manage/IncentiveTypes'))
const ViewIncentiveTypePage = lazyLoad(() => import('@/pages/manage/IncentiveTypes/View'))
const CreateIncentiveTypePage = lazyLoad(() => import('@/pages/manage/IncentiveTypes/Create'))

const YardsPage = lazyLoad(() => import('@/pages/manage/yards'))
const ViewYardsPage = lazyLoad(() => import('@/pages/manage/yards/view'))
const CreateYardsPage = lazyLoad(() => import('@/pages/manage/yards/create'))

const ProcessFlowPage = lazyLoad(() => import('@/pages/manage/process-flows'))
const ViewProcessFlowPage = lazyLoad(() => import('@/pages/manage/process-flows/view'))

export function Router() {
  return (
    <Routes>
      <Route path="manage" element={<ManageLayout />}>
        <Route path="base-company-settings" element={<BaseCompanySettings />} />
        <Route path="currencies" element={<CurrenciesPage />} />
        <Route path="user-settings" element={<UserSettingsPage />} />
        <Route path="counters" element={<CountersPage />} />
        <Route path="roles" element={<RolesPage />} />
        <Route path="permissions" element={<PermissionsPage />} />

        <Route path="document-creator-templates">
          <Route index element={<DocumentCreatorTableTemplatesPage />} />
          <Route path="create" element={<CreateDocumentCreatorTemplatesPage />} />
          <Route path=":uuid" element={<DocumentCreatorTemplatesPage />} />
        </Route>

        <Route path="document-generator-templates">
          <Route index element={<DocumentGeneratorTableTemplatesPage />} />
          <Route path="create" element={<CreateDocumentGeneratorTemplatesPage />} />
          <Route path=":uuid" element={<DocumentGeneratorTemplatesPage />} />
        </Route>

        <Route path="category">
          <Route index element={<CategoryPage />} />
          <Route path="create" element={<UpsertCategoryPage />} />
          <Route path=":_id" element={<UpsertCategoryPage />} />
        </Route>

        <Route path="plugins">
          <Route index element={<PluginsPage />} />
          <Route path="update/:id" element={<CrudPluginPage />} />
          <Route path="create" element={<CrudPluginPage />} />
        </Route>

        <Route path="integration-details">
          <Route index element={<IntegrationDetailsPage />} />
          <Route path="create" element={<CreateIntegrationDetailsPage />} />
          <Route path=":uuid" element={<ViewIntegrationDetailPage />} />
        </Route>

        <Route path="fafs">
          <Route index element={<FafsPage />} />
          <Route path="create" element={<CreateFafPage />} />
          <Route path=":uuid" element={<ViewFafPage />} />
        </Route>

        <Route path="taxes">
          <Route index element={<TaxesPage />} />
          <Route path="create" element={<CreateTaxPage />} />
          <Route path=":uuid" element={<ViewTaxes />} />
        </Route>

        <Route path="exchange-rates">
          <Route index element={<ExchangeRatesPage />} />
          <Route path="create" element={<CreateExchangeRatePage />} />
          <Route path=":uuid" element={<ViewExchangeRatePage />} />
        </Route>

        <Route path="glcodes">
          <Route index element={<GlCodesPage />} />
          <Route path="create" element={<CreateGlCodePage />} />
          <Route path=":uuid" element={<ViewGlCodePage />} />
        </Route>

        <Route path="billing-units">
          <Route index element={<BillingUnitsPage />} />
          <Route path="create" element={<CreateBillingUnitPage />} />
          <Route path=":uuid" element={<ViewBillingUnitPage />} />
        </Route>

        <Route path="integration-mappings">
          <Route index element={<IntegrationMappingsPage />} />
          <Route path="create" element={<CreateIntegrationMappingPage />} />
          <Route path=":uuid" element={<ViewIntegrationMappingPage />} />
        </Route>

        <Route path="integration-logs">
          <Route index element={<IntegrationLogsPage />} />
          <Route path=":uuid" element={<ViewIntegrationLogPage />} />
        </Route>

        <Route path="integration-bulk-import-functions">
          <Route index element={<IntegrationBulkImportFunctionsPage />} />
          <Route path="create" element={<CreateIntegrationBulkImportFunctionsPage />} />
          <Route path=":uuid" element={<ViewIntegrationBulkImportFunctionsPage />} />
        </Route>

        <Route path="logs">
          <Route index element={<LogsPage />} />
          <Route path=":category" element={<LogsPage />} />
        </Route>

        <Route path="transport-rates">
          <Route path="create/duplicate/:uuid" element={<DuplicateTransportRatePage />} />
          <Route path="create" element={<CreateTransportRatePage />} />
          <Route path=":uuid" element={<ViewTransportRatePage />} />
          <Route index element={<TransportRatesPage />} />
        </Route>

        <Route path="rates">
          <Route index element={<RatesPage />} />
          <Route path="create" element={<CreateRatePage />} />
          <Route path=":_id" element={<ViewRatePage />} />
        </Route>

        <Route path="holidays">
          <Route index element={<HolidaysPage />} />
          <Route path="create" element={<CreateHolidayPage />} />
          <Route path=":uuid" element={<ViewHolidayPage />} />
        </Route>

        <Route path="period-closings">
          <Route index element={<PeriodClosingsPage />} />
          <Route path="create" element={<CreatePeriodClosingPage />} />
          <Route path=":uuid" element={<ViewPeriodClosingPage />} />
        </Route>

        <Route path="feature-flags">
          <Route index element={<FeatureFlagsPage />} />
          <Route path="create" element={<CreateFeatureFlagPage />} />
          <Route path=":uuid" element={<ViewFeatureFlagPage />} />
        </Route>

        <Route path="incentive-types">
          <Route index element={<IncentiveTypesPage />} />
          <Route path="create" element={<CreateIncentiveTypePage />} />
          <Route path=":uuid" element={<ViewIncentiveTypePage />} />
        </Route>

        <Route path="yards">
          <Route index element={<YardsPage />} />
          <Route path="create" element={<CreateYardsPage />} />
          <Route path=":uuid" element={<ViewYardsPage />} />
        </Route>

        <Route path="transport-area-codes">
          <Route index element={<TransportAreaCodesPage />} />
          <Route path="create" element={<ViewTransportAreaCodesPage />} />
          <Route path=":uuid" element={<ViewTransportAreaCodesPage />} />
        </Route>

        <Route path="transport-zones">
          <Route index element={<TransportZonePage />} />
          <Route path="create" element={<CreateTransportZonePage />} />
          <Route path=":uuid" element={<ViewTransportZonePage />} />
        </Route>

        <Route path="job-types">
          <Route index element={<JobTypePage />} />
          <Route path="create" element={<CreateJobTypePage />} />
          <Route path=":uuid" element={<ViewJobTypePage />} />
        </Route>

        <Route path="booking-types">
          <Route index element={<BookingTypePage />} />
          <Route path="create" element={<CreateBookingTypePage />} />
          <Route path=":uuid" element={<ViewBookingTypePage />} />
        </Route>

        <Route path="charge-categories">
          <Route index element={<ChargeCategoriesPage />} />
          <Route path="create" element={<CreateChargeCategoriesPage />} />
          <Route path=":uuid" element={<ViewChargeCategoriesPage />} />
        </Route>

        <Route path="charge-items">
          <Route index element={<ChargeItemsPage />} />
          <Route path="create" element={<CreateChargeItemsPage />} />
          <Route path=":uuid" element={<ViewChargeItemsPage />} />
        </Route>

        <Route path="quotations">
          <Route index element={<QuotationsPage />} />
          <Route path="create" element={<CreateQuotationsPage />} />
          <Route path=":uuid" element={<ViewQuotations />} />
        </Route>

        <Route path="employees">
          <Route index element={<Employees />} />
          <Route path="create" element={<CreateEmployee />} />
          <Route path=":uuid" element={<ViewEmployee />} />
        </Route>

        <Route path="companies">
          <Route index element={<Companies />} />
          <Route path="view" element={<ViewCompanies />} />
          <Route path="create" element={<CreateCompanies />} />
        </Route>

        <Route path="portal-company">
          <Route index element={<PortalCompany />} />
          <Route path="view" element={<ViewPortalCompany />} />
          <Route path="create" element={<CreatePortalCompany />} />
        </Route>

        <Route path="process-flows">
          <Route index element={<ProcessFlowPage />} />
          <Route path=":uuid" element={<ViewProcessFlowPage />} />
        </Route>
      </Route>

      <Route path="auth">
        <Route path="sign-in" element={<UniversalSignIn />} />
        <Route path="check-in" element={<CheckIn />} />
        <Route path="sign-off" element={<SignOff />} />
        <Route path="v2/sign-in" element={<SignIn />} />
        <Route path="reset-password" element={<ResetPassword />} />
      </Route>

      <Route path="activate">
        <Route path="account" element={<ActivateAccount />} />
      </Route>

      <Route element={<WithHeader />}>
        <Route path="bulk-functions" element={<BulkFunctions />} />
        <Route path="pre-planning" element={<PrePlanning />} />
        <Route path="plan-dashboard" element={<PlanningDashboard />} />
        <Route path="documents" element={<DocumentsGenerated />} />
        <Route path="documentCreator/view/:uuid" element={<DocumentCreatorView />} />

        <Route path="booking">
          <Route path="document/:uuid" element={<BookingDocument />} />
        </Route>

        <Route path="bookings">
          <Route path="new">
            <Route index element={<BookingNew />} />
            <Route path="upload-document" element={<BulkUploadDocument />} />
            <Route path="bulk" element={<BulkBooking />} />
          </Route>

          <Route path="document/:uuid" element={<BookingDocument />} />

          <Route path=":uuid/duplicate" element={<BookingDuplicate />} />
          <Route path=":uuid/cost-sheet" element={<BookingCostSheet />} />
          <Route path=":uuid/costsheet" element={<BookingCostSheet />} />
          <Route path=":uuid/cost-sheet/proposed" element={<BookingCostSheet />} />
          <Route path=":uuid/costsheet/proposed" element={<BookingCostSheet />} />
          <Route path=":uuid/cover-page" element={<BookingCoverPage />} />
          <Route path=":uuid/edit" element={<BookingEdit />} />

          <Route path=":no" element={<Bookings />} />
        </Route>

        <Route path="vouchers">
          <Route index element={<Vouchers />} />
          <Route path="advice" element={<VouchersAdvice />} />
          <Route path="summary" element={<VouchersSummary />} />
          <Route path=":uuid/view" element={<VouchersView />} />
          <Route path=":uuid" element={<Vouchers />} />
        </Route>

        <Route path="reports">
          <Route index element={<ReportsPage />} />
          <Route path="create" element={<CreateReportPage />} />
          <Route path=":uuid" element={<ViewReportPage />} />
        </Route>

        <Route path="reports-legacy">
          <Route path=":key" element={<ReportsLegacyView />} />
          <Route index element={<ReportsLegacyIndex />} />
        </Route>

        <Route path="superadmin" element={<SuperAdminLayout />}>
          <Route index element={<SuperAdminAreaIndex />} />

          <Route path="base-companies">
            <Route index element={<SuperAdminBaseCompaniesIndex />} />
            <Route path="create" element={<SuperAdminBaseCompaniesCreate />} />
            <Route path=":uuid" element={<SuperAdminBaseCompaniesView />} />
          </Route>

          <Route path="superadmins">
            <Route index element={<SuperAdminsIndex />} />
            <Route path="create" element={<SuperAdminsCreate />} />
          </Route>
        </Route>

        <Route path="transport">
          <Route path="monitoring">
            <Route path="job-status" element={<JobMonitoringPage />} />
            <Route path="trip-status" element={<TripMonitoringPage />} />
            <Route path="empty-trips" element={<EmptyTripsPage />} />
            <Route path="leg-activities-report" element={<LegActivitiesReportPage />} />
          </Route>

          <Route path="operation">
            <Route index element={<TransportOperation />} />
            <Route path="incentiveVoucher" element={<TransportIncentiveVoucher />} />
            <Route path="incentiveShift" element={<TransportShift />} />
          </Route>

          <Route path="manifests" element={<TransportManifests />} />

          <Route path="planning">
            <Route index element={<TransportPlanningPage />} />
            <Route path="vehicle" element={<TransportVehiclePage />} />
            <Route path="unplanned" element={<TransportUnplannedPage />} />
          </Route>

          <Route path="dashboard" element={<TransportDashboardPage />} />
        </Route>

        <Route path="dashboard">
          <Route index element={<Dashboard />} />
          <Route path="report" element={<DashboardReport />} />
          <Route path=":key/:starred" element={<Dashboard />} />
          <Route path=":key" element={<Dashboard />} />
        </Route>

        <Route index element={<Index />} />
      </Route>
    </Routes>
  )
}
