import type { useFeatureFlagProps } from '@/hooks/useFeatureFlag'
import type { ReactNode } from 'react'

import { Children, createElement, isValidElement } from 'react'
import isEmpty from 'lodash/isEmpty'

import NoAccess from '@/components/Shared/NoAccess'
import Spinner from '@/components/Spinner'
import useFeatureFlag from '@/hooks/useFeatureFlag'
import responseHandler from '@/utils/responseHandler'

interface FeatureGuardProps extends useFeatureFlagProps {
  ghost?: boolean
  children: ReactNode
  childProps?: Record<string, unknown>
}

const FeatureGuard = ({
  name,
  children,
  ghost = true,
  baseCompanyUuid,
  childProps = {}
}: FeatureGuardProps) => {
  const { error, loading, isEnabled } = useFeatureFlag({ name, baseCompanyUuid })

  if (loading) {
    return <Spinner />
  }

  if (error) {
    responseHandler(
      `Error occurred when checking Feature Flag "${name}": ${error.message}`,
      'error'
    )
    return null
  }

  if (isEnabled) {
    // If we have childProps, merge them with the existing child props
    if (!isEmpty(childProps)) {
      const child = Children.only(children)
      if (isValidElement(child) && 'ref' in child) {
        return (
          <>
            {createElement(child.type, {
              ...child.props,
              ...childProps,
              key: child.key,
              ref: child.ref
            })}
          </>
        )
      }
    }

    // If no childProps or invalid element, render children as is
    return <>{children}</>
  }

  if (ghost) {
    return null
  }

  return (
    <NoAccess
      text={
        <div className="flex flex-col gap-1">
          <span>{`Feature "${name}" is currently disabled.`}</span>
          <a href="/manage/feature-flags" target="_blank">
            Manage feature flags
          </a>
        </div>
      }
    />
  )
}

export default FeatureGuard
