import type { DynamicField } from '@/types/graphql'
import type { SelectProps } from 'antd-v5'
import { DynamicFieldQuery, DynamicFieldTag } from '@/types/graphql'

import { useState } from 'react'
import { Select } from 'antd-v5'

import { getSelectQueryPropsForDynamicField } from '@/components/Booking/DynamicField/helper'
import SelectWithQuery from '@/components/Shared/SelectWithQuery'

type DynamicFieldSelectorProps = SelectProps & {
  dynamicField: DynamicField | null
  autoSelectFirst?: boolean
}

const parseValue = ({ value, multiple }: { value: any; multiple: boolean }) => {
  let v = value
  if (!v && multiple) v = []

  if (typeof v === 'object') v = v?.uuid ? v.uuid : v

  return v
}

const parseOptions = (df: DynamicField | null) =>
  df?.query === DynamicFieldQuery.CustomValues ? df.customValues?.split('\n') || [] : undefined

const parseMode = (df: DynamicField | null): 'tags' | 'multiple' | undefined =>
  df?.query === DynamicFieldQuery.CustomValues && !df.customValues
    ? 'tags'
    : df?.tags?.includes(DynamicFieldTag.Multiple)
      ? 'multiple'
      : undefined

const DynamicFieldSelector = ({ dynamicField, ...props }: DynamicFieldSelectorProps) => {
  const [query] = useState(
    getSelectQueryPropsForDynamicField(dynamicField?.query, dynamicField?.companyTypes)
  )
  const [options] = useState<string[] | undefined>(parseOptions(dynamicField))

  const isNonEditable = Boolean(dynamicField?.tags?.includes(DynamicFieldTag.NonEditable))

  const sharedProps = {
    ...props,
    mode: parseMode(dynamicField),
    value: parseValue({
      value: props.value,
      multiple: Boolean(dynamicField?.tags?.includes(DynamicFieldTag.Multiple))
    }),
    disabled: isNonEditable
  }

  if (!dynamicField) return null

  const selectOptions = options?.map(option => ({
    label: option,
    value: option
  }))

  return (
    <>
      {options?.length ? (
        <Select {...sharedProps} options={selectOptions} />
      ) : query ? (
        // @ts-expect-error - weak type because spread
        <SelectWithQuery {...query} {...sharedProps} />
      ) : null}
    </>
  )
}

export default DynamicFieldSelector
