import { memo } from 'react'
import { LoadingOutlined } from '@ant-design/icons'

const Spinner = () => {
  return (
    <div className="text-center py-5">
      <LoadingOutlined spin className="text-2xl text-primary" />
    </div>
  )
}

export default memo(Spinner)
