import type { Voucher } from '@/types/graphql'

import { memo } from 'react'
import { CSVLink } from 'react-csv'
import { Button } from 'antd-v5'
import get from 'lodash/get'
import reduce from 'lodash/reduce'
import moment from 'moment'

import { getLocalDateString, getLocalDateTimeString } from '@/components/RelativeDateTime'
import { dotToCamelCase } from '@/utils/u'

const keys = [
  'uuid',
  'isCreditNote',
  'isJournalVoucher',
  'paymentType',
  'invoiceNumber',
  'voucherNumber',
  'issueDate',
  'accountDate',
  'dueDate',
  'status',
  'transactionType',
  'customer.name',
  'customer.code',
  'customer.debtorCode',
  'customer.creditorCode',
  'vendor.name',
  'vendor.code',
  'vendor.debtorCode',
  'vendor.creditorCode',
  'type',
  'subTotal',
  'taxTotal',
  'total',
  'balance',
  'term',
  'description',
  'internalDescription',
  'contactPerson',
  'createdAt',
  'updatedAt'
]

const getVoucherFields = voucher => {
  return reduce(
    keys,
    (result, key) => {
      result[dotToCamelCase(key)] = get(voucher, key)
      return result
    },
    {}
  )
}

const getData = vouchers => {
  const vouchersData = vouchers.map((v, i) => {
    const voucherFields: Voucher = getVoucherFields(v)
    const transformVoucher = {
      no: i + 1,
      ...voucherFields,
      isCreditNote: voucherFields.isCreditNote?.toString(),
      isJournalVoucher: voucherFields.isJournalVoucher?.toString(),
      accountDate: getLocalDateString(voucherFields.accountDate),
      dueDate: getLocalDateString(voucherFields.dueDate),
      issueDate: getLocalDateString(voucherFields.issueDate),
      createdAt: getLocalDateTimeString(voucherFields.createdAt),
      updatedAt: getLocalDateTimeString(voucherFields.updatedAt),
      bookingNo: v.bookings?.map(b => b.no)?.join(', ')
    }
    return transformVoucher
  })
  return vouchersData
}

const ExportButton = ({ vouchers = [] }: { vouchers: Voucher[] }) => {
  const fileName = `voucher export ${moment().format('YYYY-MM-DD')}.csv`

  return (
    <CSVLink data={getData(vouchers)} filename={fileName}>
      <Button disabled={vouchers.length === 0}>Export</Button>
    </CSVLink>
  )
}

export default memo(ExportButton)
