import type { CategoryInput } from '@/types/graphql'
import { ActiveStatus, CategoryOptions } from '@/types/graphql'

import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ArrowLeftOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@apollo/client'
import {
  Button,
  Flex,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
  Spin,
  Tooltip,
  Typography
} from 'antd-v5'
import { startCase } from 'lodash'

import { defaultFormItemLayout as layout } from '@/components/Manage/FormItemMapper'
import EnumSelect from '@/components/Select/EnumSelect'
import UppercaseOnlyTextField from '@/components/TextFields/UppercaseOnly'
import {
  CATEGORY,
  CREATE_CATEGORY,
  DELETE_CATEGORY,
  UNDELETE_CATEGORY,
  UPDATE_CATEGORY
} from '@/graphql/category'
import notify from '@/utils/notify'

const UpsertCategoryPage = () => {
  const navigate = useNavigate()
  const { _id } = useParams()
  const { pathname } = useLocation()

  const [form] = Form.useForm<CategoryInput>()
  const categoryOptions = Form.useWatch<CategoryOptions[]>('options', form)

  const { data, loading, error } = useQuery(CATEGORY, { variables: { _id }, skip: !_id })
  const [createCategory, { loading: createLoading, error: createError }] = useMutation(
    CREATE_CATEGORY,
    { onCompleted: () => notify('Category created successfully', 'success', 3) }
  )
  const [updateCategory, { loading: updateLoading, error: updateError }] = useMutation(
    UPDATE_CATEGORY,
    { onCompleted: () => notify('Category updated successfully', 'success', 3) }
  )
  const [deleteCategory, { loading: deleteLoading, error: deleteError }] = useMutation(
    DELETE_CATEGORY,
    { onCompleted: () => notify('Category deleted successfully', 'success', 3) }
  )
  const [undeleteCategory, { loading: undeleteLoading, error: undeleteError }] = useMutation(
    UNDELETE_CATEGORY,
    { onCompleted: () => notify('Category undeleted successfully', 'success', 3) }
  )

  const isUpdate = !pathname.endsWith('/create')

  const defaultRules = (name: string) => [
    { required: true, message: `${startCase(name)} is required.` }
  ]

  const defaultPlaceholder = (name: string) => `Enter category ${name}...`

  const handleUpsertAction = async () => {
    if (!isUpdate) {
      try {
        const formValues = await form.validateFields()
        const createCategoryInput = { ...formValues, values: JSON.stringify(formValues.values) }
        await createCategory({ variables: { createCategoryInput } })
        form.resetFields()
        navigate(-1)
      } catch (error) {
        console.error('Validation failed:', error)
      }
    }

    if (isUpdate && _id) {
      try {
        const formValues = await form.validateFields()
        const updateCategoryInput = { ...formValues, values: JSON.stringify(formValues.values) }
        await updateCategory({ variables: { categoryId: _id, updateCategoryInput } })
        form.resetFields()
        navigate(-1)
      } catch (error) {
        console.error('Validation failed:', error)
      }
    }
  }

  const handleActivationAction = async () => {
    if (data?.category?.status === ActiveStatus.Active) {
      await deleteCategory({ variables: { deleteCategoryId: _id } })
      navigate(-1)
    } else {
      await undeleteCategory({ variables: { undeleteCategoryId: _id } })
      navigate(-1)
    }
  }

  const Actions = () => (
    <Flex justify="space-between" align="center">
      <Button size="small" ghost onClick={() => navigate(-1)}>
        <ArrowLeftOutlined style={{ color: 'GrayText' }} />
        <Typography.Text>Back</Typography.Text>
      </Button>
      <Flex gap="small">
        {isUpdate && (
          <Button
            size="small"
            danger
            onClick={handleActivationAction}
            loading={deleteLoading || undeleteLoading}
          >
            {data?.category?.status === ActiveStatus.Active ? 'Deactivate' : 'Activate'}
          </Button>
        )}
        <Button
          size="small"
          type="primary"
          onClick={handleUpsertAction}
          loading={createLoading || updateLoading}
        >
          {isUpdate ? 'Update' : 'Create'}
        </Button>
      </Flex>
    </Flex>
  )

  if (createError || updateError || deleteError || undeleteError || error) {
    notify(
      createError?.message ||
        updateError?.message ||
        deleteError?.message ||
        undeleteError?.message ||
        error?.message ||
        'An error occurred',
      'error'
    )
  }

  if (loading) return <Spin />

  return (
    <Flex vertical className="p-4" gap="middle">
      <Flex vertical>
        <Typography.Title level={2}>Create category</Typography.Title>
        <Typography.Paragraph>Please fill in all required fields.</Typography.Paragraph>
      </Flex>
      <Actions />
      <Form
        disabled={data?.category?.status === ActiveStatus.Deleted}
        form={form}
        initialValues={
          isUpdate ? { ...data?.category, values: JSON.parse(data?.category?.values) } : {}
        }
        className="w-full"
      >
        <Form.Item label="Name" name="name" rules={defaultRules('name')} {...layout}>
          <Input placeholder={defaultPlaceholder('name')} />
        </Form.Item>
        <Form.Item label="Code" name="code" rules={defaultRules('code')} {...layout}>
          <UppercaseOnlyTextField placeholder={defaultPlaceholder('code')} />
        </Form.Item>
        <Form.Item label="Description" name="description" {...layout}>
          <Input placeholder={defaultPlaceholder('description')} />
        </Form.Item>
        <Form.Item label="Model" name="model" rules={defaultRules('model')} {...layout}>
          <EnumSelect name="CategoryModel" placeholder="Select category model" />
        </Form.Item>
        <Form.Item label="Sort" name="sort" {...layout} rules={defaultRules('sort')} {...layout}>
          <InputNumber name="sort" placeholder={defaultPlaceholder('sort')} className="w-full" />
        </Form.Item>
        <Form.Item label="Options" name="options" {...layout}>
          <EnumSelect
            name="CategoryOptions"
            placeholder="Select category options"
            mode="multiple"
          />
        </Form.Item>
        {!categoryOptions?.includes(CategoryOptions.NonEditable) && (
          <Form.Item
            label={
              <Space>
                Values
                <Tooltip title="Add values to the category by input options and click enter">
                  <QuestionCircleOutlined className="cursor-help" />
                </Tooltip>
              </Space>
            }
            name="values"
            rules={defaultRules('values')}
            {...layout}
          >
            <Select allowClear mode="tags" placeholder={defaultPlaceholder('values')} removeIcon />
          </Form.Item>
        )}
      </Form>
      <Actions />
    </Flex>
  )
}

export default UpsertCategoryPage
