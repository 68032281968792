import type { CollapseProps } from 'antd-v5'

import { useCallback } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { Button, Collapse, Divider, Flex, Form, Select } from 'antd-v5'

import { GENERATE_REPORT_MUTATION } from '@/components/ReportsNew/schema'
import { ListWrapper } from '@/components/Transport/Dashboard'
import { useReportStore } from '@/store/report'
import notify from '@/utils/notify'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 15 }
  }
}

interface SearchFilterFormProps {
  reports: Report[]
}

const SearchFilterForm = ({ reports }: SearchFilterFormProps) => {
  const [form] = Form.useForm()
  const reportUuid = useReportStore.use.reportUuid()
  const setReportUuid = useReportStore.use.setReportUuid()
  const selectedReportEmbeddedUrl = useReportStore.use.selectedReportEmbeddedUrl()
  const setSelectedReportEmbeddedUrl = useReportStore.use.setSelectedReportEmbeddedUrl()
  const [generateReport, { loading }] = useMutation(GENERATE_REPORT_MUTATION)

  const options = reports?.map((report: any) => ({ label: report.name, value: report.uuid }))

  const reportSelected = useCallback((value: string) => {
    setReportUuid(value)
  }, [])

  const handleGenerateReport = async () => {
    try {
      const response = await generateReport({
        variables: {
          input: { reportUuid }
        }
      })
      if (response.data?.generateReport?.success) {
        setSelectedReportEmbeddedUrl(response.data?.generateReport?.message)
      } else {
        notify('Failed to generate report', 'error')
      }
    } catch (err) {
      notify('Error generating report', 'error')
    }
  }

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: 'Dashboard Search',
      children: (
        <Form {...formItemLayout} form={form}>
          <Form.Item label="Report" name="report">
            <Select
              showSearch
              placeholder="Select a report"
              options={options}
              onChange={reportSelected}
              value={reportUuid}
            />
          </Form.Item>
          <Divider style={{ margin: '16px 0' }} />
          <Flex justify="end">
            <Button
              type="primary"
              onClick={handleGenerateReport}
              disabled={!reportUuid || loading}
              loading={loading}
            >
              <SearchOutlined />
              Generate Report
            </Button>
          </Flex>
        </Form>
      )
    }
  ]

  return (
    <ListWrapper>
      <Collapse items={items} defaultActiveKey={['1']} />
      {selectedReportEmbeddedUrl && (
        <iframe
          src={selectedReportEmbeddedUrl}
          className="w-full"
          style={{ height: '4000px', border: 'none' }}
          title="Metabase"
        />
      )}
    </ListWrapper>
  )
}
export default SearchFilterForm
