import type { ColumnProps } from 'antd/lib/table'

import { capitalize } from 'lodash'

import { StatusText } from '@/components/TableView/Styled'

interface StatusFilterProps {
  status: any
  isCaps?: boolean
  defaultFilter?: string[]
}

const StatusFilter = ({
  status,
  isCaps = true,
  defaultFilter
}: StatusFilterProps): ColumnProps<any> => {
  const statusOptions = Object.values(status).map((st: any) => ({
    text: capitalize(st),
    value: isCaps ? st : st.toLowerCase()
  }))

  return {
    title: 'Status',
    key: 'status',
    filters: statusOptions,
    width: 90,
    onFilter: (value, record) => record.status === value,
    render: record => <StatusText>{record.status || '-'}</StatusText>,
    defaultFilteredValue: defaultFilter?.length ? defaultFilter : undefined
  }
}

export default StatusFilter
