import { gql } from '@/types'

export const PORTAL_COMPANIES_QUERY = gql(`
  query portalCompaniesTable($query: String, $limit: Int, $offset: Int) {
    portalCompanies(query: $query, limit: $limit, offset: $offset) {
      rows {
        uuid
        name
        status
      }

      pageInfo {
        limit
        offset
        count
      }
    }
  }
`)

export const PORTAL_COMPANY_QUERY = gql(`
  query portalCompanySingle($uuid: UUID) {
    portalCompany(uuid: $uuid) {
      uuid
      name
      status
    }
  }
`)
export const PORTAL_COMPANY_COMPANIES_QUERY = gql(`
  query PortalCompanyCompanies($uuid: UUID) {
    portalCompany(uuid: $uuid) {
      uuid
      viewCompanies {
        uuid
        code
        name
        types
      }
      bookCompanies {
        uuid
        code
        name
        types
      }
      bulkImportFunctions {
        uuid
        name
        description
      }
    }
  }
`)
export const PORTAL_COMPANY_EMPLOYEES_QUERY = gql(`
  query portalCompanyEmployees($uuid: UUID) {
    portalCompany(uuid: $uuid) {
      uuid
      employees {
        uuid
        roles
        status
        user {
          email
        }
      }
    }
  }
`)
export const PORTAL_COMPANY_REPORTS_QUERY = gql(`
  query portalCompanyReports($uuid: UUID) {
    portalCompany(uuid: $uuid) {
      uuid
      reports {
        rows {
          uuid
          name
          reportUuids
          portalCompanyUuid
          reports {
            uuid
            name
            model
            description
            categories
            columns {
              header
              formatFnc
              formatParams
            }
          }
          hours
        }
      }
    }
  }
`)
export const ADD_PORTAL_REPORT = gql(`
  mutation assignPortalCompanyReport1($input: AssignPortalCompanyInput) {
    assignPortalCompanyReport(input: $input) {
      success
      message
    }
  }
`)
export const DELETE_PORTAL_REPORT = gql(`
  mutation unassignPortalCompanyReport1($uuid: UUID) {
    unassignPortalCompanyReport(uuid: $uuid) {
      success
      message
    }
  }
`)
export const EMPLOYEES_QUERY = gql(`
  query employeesForPortalUser($query: String, $limit: Int, $offset: Int, $companyUuids: [UUID]) {
    employees(query: $query, limit: $limit, offset: $offset, companyUuids: $companyUuids) {
      rows {
        roles
      }
    }
  }
`)
export const CREATE_PORTAL_COMPANY_QUERY = gql(`
  mutation createPortalCompany($input: CreatePortalCompanyInput) {
    createPortalCompany(input: $input) {
      uuid
      name
    }
  }
`)
export const UPDATE_PORTAL_COMPANY_QUERY = gql(`
  mutation UpdatePortalCompany($input: UpdatePortalCompanyInput) {
    updatePortalCompany(input: $input) {
      uuid
      name
    }
  }
`)
export const INVITE_PORTAL_COMPANY_QUERY = gql(`
  mutation InvitePortalCompany($input: InvitePortalCompanyInput) {
    invitePortalCompany(input: $input) {
      success
      message
    }
  }
`)
export const UNINVITE_PORTAL_COMPANY_QUERY = gql(`
  mutation uninvitePortalCompany($employeeUuid: UUID, $portalCompanyUuid: UUID) {
    unInvitePortalCompany(employeeUuid: $employeeUuid, portalCompanyUuid: $portalCompanyUuid) {
      success
      message
    }
  }
`)
export const DELETE_PORTAL_COMPANY = gql(`
  mutation DeletePortalCompany($uuid: UUID) {
    deletePortalCompany(uuid: $uuid) {
      success
      message
    }
  }
`)
export const UNDELETE_PORTAL_COMPANY = gql(`
  mutation UnDeletePortalCompany($uuid: UUID) {
    undeletePortalCompany(uuid: $uuid) {
      success
      message
    }
  }
`)

export const ADD_BULK_IMPORT_FUNCTION_SETTING = gql(`
  mutation CreateBulkImportFunctionSetting($input: BulkImportFunctionSettingInput!) {
    createBulkImportFunctionSetting(input: $input) {
      success
    }
  }
`)

export const DELETE_BULK_IMPORT_FUNCTION_SETTING = gql(`
  mutation DeleteBulkImportFunctionSetting($input: BulkImportFunctionSettingInput!) {
    deleteBulkImportFunctionSetting(input: $input) {
      success
    }
  }
`)

export const METABASE_REPORT_VIEW_QUERY = gql(`
  query ReportAvailableMetabaseViews {
    reportAvailableMetabaseViews {
      description
      id
      name
      resource
      type
    }
  }
`)
