import { Table } from 'antd-v5'

import { NoItemsFound } from '@/components/TableView/Styled'

interface TableColumnsProps {
  items?: any[]
  pagination: {
    total: number
    offset: number
    limit: number
    onSizeChange: (page: number) => void
  }
  renderColumns: () => any[]
  usePagination: boolean
  selfGenerateKey?: boolean
}

const TableColumns = ({
  items = [],
  pagination,
  renderColumns,
  usePagination,
  selfGenerateKey = true
}: TableColumnsProps) => {
  const { onSizeChange } = pagination

  if (items.length === 0) {
    return <NoItemsFound>No items available.</NoItemsFound>
  }

  let dataSource: any[] = []

  if (selfGenerateKey) {
    dataSource = items?.map((item, index) => ({
      ...item,
      key: item.uuid || index
    }))
  } else {
    dataSource = items?.map(item => ({
      ...item
    }))
  }

  return (
    <Table
      columns={renderColumns()}
      dataSource={dataSource}
      pagination={
        usePagination
          ? {
              total: pagination?.total,
              simple: true,
              current: pagination?.offset / pagination?.limit + 1,
              pageSize: pagination?.limit,
              onChange: page => onSizeChange(page - 1)
            }
          : false
      }
    />
  )
}

export default TableColumns
